import {
  Accordion,
  Box,
  Button,
  ButtonsGroup,
  Card,
  Footer,
  Grid,
  Heading,
  InputSelect,
  InputText,
  Maps,
  PageHeader,
  PageWrapper,
  Typo
} from "#/atoms";
import { DESKTOP_SPACING, HPOSITION, NATURAL } from "@/theme";
import { FC, memo } from "react";
import { useInput, useInputSelect, useMaps } from "@/hooks";

import { HeadingIcon } from "#/molecules";
import { WorkerDetailsProps } from "./WorkerDetails.interface";
import { useParams } from "react-router-dom";

const WorkerDetails: FC<WorkerDetailsProps> = ({ 
  onChangePassword,
  onSaveAccountDetails,
  onSaveDetails,
  onSaveServices,
  workerData,
  workerServices }) => {
  const { mapsAddress, onBlur, onChange } = useMaps();

  const { id } = useParams<'id'>();
  const workerId = id ?? '';

  const login = useInput(workerData?.accountDetails.login);
  const email = useInput(workerData?.accountDetails.email);
  const accountPhone = useInput(workerData?.accountDetails.accountPhoneNumber);
  const firstName = useInput(workerData?.accountDetails.firstName);
  const lastName = useInput(workerData?.accountDetails.lastName);
  const daysToPay = useInput((workerData?.accountDetails.daysToPayInvoices ?? 0).toString());

  const phoneNumber = useInput(workerData?.workerDetails.phoneNumber);
  const houseName = useInput(workerData?.workerDetails.houseName);
  const houseNumber = useInput(workerData?.workerDetails.houseNumber);
  const road = useInput(workerData?.workerDetails.road);
  const area = useInput(workerData?.workerDetails.area);
  const town = useInput(workerData?.workerDetails.town);
  const county = useInput(workerData?.workerDetails.county);
  const postcode = useInput(workerData?.workerDetails.postCode);
  const addressNotes = useInput(workerData?.workerDetails.notes);
  const fullTime = useInputSelect('fullTime', workerData?.workerDetails.fullTimeType ?? false);

  const bankAccountName = useInput(workerData?.bankDetails.bankAccountName);
  const bankAccountNumber = useInput(workerData?.bankDetails.bankAccountNumber);
  const bankName = useInput(workerData?.bankDetails.bankName);
  const bankSortCode = useInput(workerData?.bankDetails.bankSortCode);

  const handleSaveAccountDetails = async () => {
    onSaveAccountDetails({
      AccountPhoneNumber: accountPhone.value,
      CountryId: 1,
      DaysToPayInvoices: parseInt(daysToPay.value, 10),
      DefaultPhotos: 0,
      Email: email.value,
      FirstName: firstName.value,
      Id: workerId,
      LastName: lastName.value,
      UserName: login.value,
      UserUpsellPrice: 0
    });
  };

  const handleChangePassword = async () => {
    onChangePassword(workerId);
  }

  const handleSaveDetails = async () => {
    onSaveDetails(workerId, {
      AddressId: 0,
      AddressString: null,
      Area: area.value,
      Country: county.value,
      DoorNumber: null,
      Flat: null,
      FullTimeType: fullTime.checked,
      GoogleCalendarId: null,
      HouseName: houseName.value,
      HouseNumber: houseNumber.value,
      Notes: "",
      PhoneNumber: phoneNumber.value,
      PostCode: postcode.value,
      Rating: 0,
      Road: road.value,
      Town: town.value
    })
  };

  const handleCheckedChange = async (ev) =>{
    console.log(ev.target);
    // const workerService = workerServices.find(w => w.id === serviceId);
    // workerService?.checked = 
  }

  const handleSaveServices = async () => {
    onSaveServices(workerId,
      []);
  }

  const handleCopyToClipboard = async() => {
    const text = "Bank Account Name: " + bankAccountName.value
                        + ", Sort Code: " + bankSortCode.value
                        + ", Bank Account Number: " + bankAccountNumber.value
                        + ", Bank Name: " + bankName.value
                        navigator.clipboard.writeText(text).then(() => {
                            //l.info("{{customers.bankDetails.bankInformationCopied}}");
                        });
  }

  return (
    <>
      <PageWrapper pb="100px">
        <PageHeader>
          <Heading scale="h1" fontColor={NATURAL.K}>Edit Profile</Heading>
        </PageHeader>

        <Card padding="0">
          <Box padding={`${DESKTOP_SPACING.EL}px`}>
            <Accordion
              header={<HeadingIcon icon="contacts" title="Account Details" />}
              onToggle={() => null}
              showArrow
              state="open"
            >
              <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(3, 30%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
                <InputText type="text" label="Login" value={login.value} onChange={login.onChange} />
                <InputText type="email" label="E-mail Address" value={email.value} onChange={email.onChange} />
                <InputText type="tel" label="Account Phone Number" value={accountPhone.value} onChange={accountPhone.onChange} />
                <InputText type="text" label="First Name" value={firstName.value} onChange={firstName.onChange} />
                <InputText type="text" label="Last Name" value={lastName.value} onChange={lastName.onChange} />
                <InputText type="number" label="Days To Pay Invoices" value={daysToPay.value} onChange={daysToPay.onChange} />
              </Grid>
              <ButtonsGroup align={HPOSITION.RIGHT} fullWidth>
                <Button onClick={() => handleSaveAccountDetails() }>
                  Save
                </Button>
                <Button onClick={() => handleChangePassword() }>
                  Change Password
                </Button>
              </ButtonsGroup>
            </Accordion>
          </Box>
        </Card>

        <Card padding="0">
          <Grid gridTemplateColumns="repeat(2, 1fr)">
            <Box padding={`${DESKTOP_SPACING.EL}px`}>
              <HeadingIcon
                icon="location"
                title="Address Details"
              />
              <InputText type="text" label="Phone Number" value={phoneNumber.value} onChange={phoneNumber.onChange} />
              <InputText type="text" label="House Name" value={houseName.value} onChange={houseName.onChange} />
              <InputText type="text" label="Number" value={houseNumber.value} onChange={houseNumber.onChange} />
              <InputText type="text" label="Road" value={road.value} onChange={road.onChange} />
              <InputText type="text" label="Area" value={area.value} onChange={area.onChange} />
              <InputText type="text" label="Town" value={town.value} onChange={town.onChange} />
              <InputText type="text" label="County" value={county.value} onChange={county.onChange} />
              <InputText type="text" label="Postcode" value={postcode.value} onBlur={onBlur} onChange={onChange} />
              <InputText type="text" label="Notes" value={addressNotes.value} onBlur={onBlur} onChange={onChange} />
              <InputText type="number" label="Commuting Distance" value={addressNotes.value} onBlur={onBlur} onChange={onChange} />
              <Box display="inline">
                <InputSelect type="radio" id="fullTime" name="commutingDistance" checked={fullTime.checked} label="Kilometres" value={fullTime.value} onChangeValue={fullTime.onChange} />
                <InputSelect type="radio" id="fullTime" name="commutingDistance" checked={fullTime.checked} label="Miles" value={fullTime.value} onChangeValue={fullTime.onChange} />
              </Box>
              <InputText type="text" label="Website Link" value={addressNotes.value} onBlur={onBlur} onChange={onChange} />
              <InputSelect type="checkbox" id="fullTime" name="fullTime" checked={fullTime.checked} label="Full Time Photographer" value={fullTime.value} onChangeValue={fullTime.onChange} />
              <Typo>Google Calendar</Typo>
              <Typo>{ workerData?.workerDetails.googleCalendarId ? "" : "Google Calendar is not enabled right now." }</Typo>
              <ButtonsGroup align={HPOSITION.RIGHT} fullWidth>
                <Button onClick={() => handleSaveDetails() }>
                  Save
                </Button>
              </ButtonsGroup>
            </Box>
            <Box>
              <Maps address={mapsAddress} />
            </Box>
          </Grid>
        </Card>

        <Card padding="0">
          <Box padding={`${DESKTOP_SPACING.EL}px`}>
            <Accordion
              header={<HeadingIcon icon="services" title="Services" />}
              onToggle={() => null}
              showArrow
              state="open"
            >
              { workerServices.map((service) => (<InputSelect 
              key={service.id} 
              label={service.name} 
              type="checkbox" 
              checked={service.checked} 
              value={service.name}
              onChange={handleCheckedChange}
             />) ) }
             <ButtonsGroup align={HPOSITION.RIGHT} fullWidth>
              <Button onClick={() => handleSaveServices()}>
                Save
              </Button>
            </ButtonsGroup>
            </Accordion>
          </Box>
        </Card>

        <Card width="49%" padding="0" display="inline-block">
          <Box padding={`${DESKTOP_SPACING.EL}px`}>
            <Accordion
              header={<HeadingIcon icon="wallet" title="Bank Details" />}
              onToggle={() => null}
              showArrow
              state="open"
            >
             <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(1, 100%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
                <InputText type="text" label="Bank Account Name" value={bankAccountName.value} onChange={bankAccountName.onChange} />
                <InputText type="text" label="Sort Code" value={bankSortCode.value} onChange={bankSortCode.onChange} />
                <InputText type="text" label="Account Number" value={bankAccountNumber.value} onChange={bankAccountNumber.onChange} />
                <InputText type="text" label="Bank Name" value={bankName.value} onChange={bankName.onChange} />
              </Grid>
             <ButtonsGroup align={HPOSITION.RIGHT} fullWidth>
              <Button onClick={() => handleCopyToClipboard()}>
                Copy to Clipboard
              </Button>
              <Button onClick={() => handleSaveServices()}>
                Save
              </Button>
            </ButtonsGroup>
            </Accordion>
          </Box>
        </Card>

        <Card width="49%"  padding="0" marginLeft="10px" display="inline-block">
          <Box padding={`${DESKTOP_SPACING.EL}px`} display="inline-block" position="relative">
            <Accordion
              header={<HeadingIcon icon="wallet" title="Post Codes" />}
              onToggle={() => null}
              showArrow
              state="open"
            >
             
             <ButtonsGroup align={HPOSITION.RIGHT} fullWidth>
              <Button onClick={() => handleSaveServices()}>
                Save
              </Button>
            </ButtonsGroup>
            </Accordion>
          </Box>
        </Card>

        <Card padding="0">
          <Box padding={`${DESKTOP_SPACING.EL}px`}>
            <Accordion
              header={<HeadingIcon icon="services" title="Portfolio" />}
              onToggle={() => null}
              showArrow
              state="open"
            >
              { workerServices.map((service) => (<InputSelect 
              key={service.id} 
              label={service.name} 
              type="checkbox" 
              checked={service.checked} 
              value={service.name}
              onChange={handleCheckedChange}
             />) ) }
             <ButtonsGroup align={HPOSITION.RIGHT} fullWidth>
              <Button onClick={() => handleSaveServices()}>
                Save
              </Button>
            </ButtonsGroup>
            </Accordion>
          </Box>
        </Card>

       </PageWrapper>
      <Footer>
         <Button>Save</Button>
      </Footer>
    </>
  );
};

export default memo(WorkerDetails);
