import { Accordion, Card, Grid, InputSelect, InputText, Typo } from "#/atoms";
import { DESKTOP_SPACING, FONT_SIZE, NATURAL } from "@/theme";
import { FC, memo } from "react";

import { AdditionalInfoCardProps } from "./AdditionalInfo.interface";
import { HeadingIcon } from "#/molecules/HeadingIcon";

const AdditionalInfoCard: FC<AdditionalInfoCardProps> = ({
  accordionState = 'open',
  poNumber,
  bedrooms,
  floorArea,
  parking,
  virtualTourPartial
}) => {
  return (
    <Card>
      <Accordion
        header={<HeadingIcon icon="location" title="Additional Information" />}
        showArrow
        state={accordionState}
      >
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 40%)" margin="0">
        <InputText
            type="text"
            label="PO Number"
            value={poNumber.value}
            onChange={poNumber.onChange}
          />
          <InputText
            type="number"
            label="Number of bedrooms"
            value={bedrooms.value}
            onChange={bedrooms.onChange}
          />
          <InputText
            type="number"
            label="Sq ft. of property"
            value={floorArea.value}
            onChange={floorArea.onChange}
          />
          <InputText
            type="text"
            label="Parking available"
            value={parking.value}
            onChange={parking.onChange}
          />
        </Grid>
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(1, 100%)" margin="0">
          <InputSelect 
            type="checkbox"
            checked={virtualTourPartial.checked}
            label="360 Virtual Tours - Stick to package number of images"
            value={virtualTourPartial.value}
            onChangeValue={virtualTourPartial.onChange} />
          <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>
          Please be aware that this might result in an incomplete 360 tour, with some parts potentially missing. However, you will not be charged for extra images.
          </Typo>
        </Grid>
      </Accordion>
    </Card>
  );
};

export default memo(AdditionalInfoCard);