import { BORDER_RADIUS, DESKTOP_SPACING, FONT_SIZE, FONT_WEIGHT, NATURAL, media } from "@/theme";

import { StyledHeading } from "@/components/atoms/Typography/Heading/Heading.styled";
import { StyledTypo } from "@/components/atoms/Typography/Typo/Typo.styled";
import styled from "styled-components";

export const StyledLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  ${media.laptop} {
    width: 100%;
  }
`;

export const StyledLoginForm = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px 20% 0;

  ${StyledHeading} {
    margin-bottom: ${DESKTOP_SPACING.XXL}px;
  }

  ${media.laptop} {
    margin: 30px 10% 0;
  }
`;

export const StyledSignup = styled.div`
  bottom: 0;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  width: 40%;
  background-color: black;

  ${media.laptop} {
    margin-top: ${DESKTOP_SPACING.XXL}px;
    position: relative;
    width: 100%;
  }
`;

export const StyledSignupForm = styled.div`
  margin-top: 140px;
  padding: ${DESKTOP_SPACING.XXL}px;
  position: relative;
  text-align: center;
  z-index: 2;

  ${StyledHeading} {
    margin-bottom: ${DESKTOP_SPACING.XL}px;
  }

  ${StyledTypo} {
    margin-bottom: ${DESKTOP_SPACING.XXXL}px;
  }

  ${media.laptop} {
    margin-top: 30px;
  }
`;

export const StyledLogo = styled.div`
  margin: ${DESKTOP_SPACING.EL}px 0 0 ${DESKTOP_SPACING.EL}px;
  text-align: center;
  z-index: 2;
`;

export const StyledSignupImage = styled.img`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 1;
  opacity: 0.7;

  ${media.laptop} {
    height: auto;
    width: 100%;
  }
`;

export const StyledGoogleButton = styled.button`
  align-items: center;
  background: #4285F4;
  border: 1px solid #4285F4;
  border-radius: ${BORDER_RADIUS.MD};
  color: ${NATURAL.A};
  display: flex;
  overflow: hidden;
  padding: 0;
`;

export const StyledGoogleIcon = styled.div`
  align-items: center;
  background: ${NATURAL.A};
  display: flex;
  height: 100%;
  padding: 0 ${DESKTOP_SPACING.LG}px;
`;

export const StyledGoogleText = styled.div`
  font-size: ${FONT_SIZE.MD};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  padding: 0 ${DESKTOP_SPACING.LG}px;
`;
