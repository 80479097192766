import { Button, Heading, PageHeader, PageWrapper } from "#/atoms";
import { CustomTable, FilterAction } from "#/molecules";
import { FC, memo, useMemo } from "react";

import { Column } from "react-table";
import { NATURAL } from "@/theme";
import { WorkerInvoicesProps } from "./WorkerInvoices.interface";

const WorkerInvoices: FC<WorkerInvoicesProps> = ({ loading, numberOfFilters, onNewInvoice, onOpenFilter, workerInvoices, onClickRow }) => {
  const data = useMemo(() => workerInvoices.map((workerInvoice) => ({
    amount: workerInvoice.amount,
    dueDate: workerInvoice.dueDate,
    fileName: workerInvoice.fileName,
    filePath: workerInvoice.filePath,
    id: workerInvoice.workerInvoiceId,
    invoiceNumber: workerInvoice.invoiceNumber,
    name: workerInvoice.userName
    
  })), [workerInvoices]);
  
  const columns: Column[] = useMemo(() => [
    {
      Header: 'Invoice Number',
      accessor: 'invoiceNumber',
    },
    {
      Header: 'User Name',
      accessor: 'name',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
    },
    {
      Header: 'Link',
      accessor: 'fileName',
    },
  ], []);

  const handleRowClick = (rawData: object) => {
    onClickRow?.(rawData['id']);
  }

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Photographer Invoices</Heading>
        <Button onClick={onNewInvoice}>Select All</Button>
        <Button onClick={onNewInvoice}>Select All Approved</Button>
        <Button onClick={onNewInvoice}>Mark As Approved</Button>
        <Button onClick={onNewInvoice}>Mark As Paid</Button>
        <Button onClick={onNewInvoice}>Find For Worker</Button>
      </PageHeader>
      <FilterAction numberOfFilters={numberOfFilters} onOpen={onOpenFilter} />
      <CustomTable columns={columns} data={data} rowProps={({ cursor: 'pointer' })}  loading={loading} pagination={{ pageIndex: 0, pageSize: 10 }} onClickRow={handleRowClick} />
    </PageWrapper>
  );
};

export default memo(WorkerInvoices);