/* eslint-disable sort-keys */
import { ORDER_STATE } from "@/types";

export const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date: Date): string => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('.');
}

export const formatShortDate = (d : Date): string => {
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);

  return day + month + year;
}

export const formatShortDateString = (dateString : string): string => {
  if (!dateString) return dateString;
  const d = new Date(dateString);
  
  return formatShortDate(d);
}

export const getOrderStateEnum = (state: number): ORDER_STATE => {
  switch (state) {
    case -1: {
      return ORDER_STATE.AWAITING_DATE;
    }
    case 0: {
      return ORDER_STATE.BOOKING_IN_PROGRESS;
    }
    case 1: {
      return ORDER_STATE.BOOKING_PLACED;
    }
    case 2: {
      return ORDER_STATE.COMPLETE;
    }
    case 3: {
      return ORDER_STATE.CONTENT_SELECTED;
    }
    case 4: {
      return ORDER_STATE.AWAITING_PAYMENT;
    }
    case 5: {
      return ORDER_STATE.ORDER_PAID;
    }
    case 6: {
      return ORDER_STATE.RETURN_TO_WORK;
    }
    case 99:
    default: {
      return ORDER_STATE.DRAFT;
    }
  }
}

export const getOrderStateNumber = (state: ORDER_STATE): number => {
  switch (state) {
    case ORDER_STATE.AWAITING_DATE: {
      return -1;
    }
    case ORDER_STATE.BOOKING_IN_PROGRESS: {
      return 0;
    }
    case ORDER_STATE.BOOKING_PLACED: {
      return 1;
    }
    case ORDER_STATE.COMPLETE: {
      return 2;
    }
    case ORDER_STATE.CONTENT_SELECTED: {
      return 3;
    }
    case ORDER_STATE.AWAITING_PAYMENT: {
      return 4;
    }
    case ORDER_STATE.ORDER_PAID: {
      return 5;
    }
    case ORDER_STATE.RETURN_TO_WORK: {
      return 6;
    }
    case ORDER_STATE.DRAFT: {
      return 99;
    }
  }
}


export const getOrderStateText = (state?: ORDER_STATE) => {
  switch (state) {
    case ORDER_STATE.AWAITING_DATE: {
      return 'Awaiting confirmation of date and time';
    }
    case ORDER_STATE.BOOKING_IN_PROGRESS: {
      return 'Booking in progress';
    }
    case ORDER_STATE.BOOKING_PLACED: {
      return 'Booking places';
    }
    case ORDER_STATE.COMPLETE: {
      return 'Work completed';
    }
    case ORDER_STATE.CONTENT_SELECTED: {
      return 'Content selected';
    }
    case ORDER_STATE.AWAITING_PAYMENT: {
      return 'Complete and waiting for payment';
    }
    case ORDER_STATE.ORDER_PAID: {
      return 'Order paid';
    }
    case ORDER_STATE.RETURN_TO_WORK: {
      return 'Return for additional work';
    }
    case ORDER_STATE.DRAFT: {
      return 'Draft completed';
    }
    default: {
      return ''
    }
  }
}

export const getYesOrNo = (value?: boolean | null): string => {
  if (value == null) {
    return ''
  }

  if (value) {
    return 'yes';
  }

  return 'no';
}

export const getBoleanFromYesOrNo = (value?: string): boolean | null => {
  if (value === 'yes') {
    return true;
  }

  if (value === 'no') {
    return false;
  }

  return null;
}

export const countryList = {
  C1: { name: 'United Kingdom', code: '44' },
  C2: { name: 'Afghanistan', code: '\\93' },
  C3: { name: 'Albania', code: '355' },
  C4: { name: 'Algeria', code: '213' },
  C5: { name: 'Andorra', code: '376' },
  C6: { name: 'Angola', code: '244' },
  C7: { name: 'Antigua & Deps', code: '1268' },
  C8: { name: 'Argentina', code: '54' },
  C9: { name: 'Armenia', code: '374' },
  C10: { name: 'Australia', code: '61' },
  C11: { name: 'Austria', code: '43' },
  C12: { name: 'Azerbaijan', code: '\\9\\94' },
  C13: { name: 'Bahamas', code: '1242' },
  C14: { name: 'Bahrain', code: '\\973' },
  C15: { name: 'Bangladesh', code: '880' },
  C16: { name: 'Barbados', code: '1246' },
  C17: { name: 'Belarus', code: '375' },
  C18: { name: 'Belgium', code: '32' },
  C19: { name: 'Belize', code: '501' },
  C20: { name: 'Benin', code: '22\\9' },
  C21: { name: 'Bhutan', code: '\\975' },
  C22: { name: 'Bolivia', code: '5\\91' },
  C23: { name: 'Bosnia Herzegovina', code: '387' },
  C24: { name: 'Botswana', code: '267' },
  C25: { name: 'Brazil', code: '55' },
  C26: { name: 'Brunei', code: '673' },
  C27: { name: 'Bulgaria', code: '35\\9' },
  C28: { name: 'Burkina', code: '226' },
  C29: { name: 'Burundi', code: '257' },
  C30: { name: 'Cambodia', code: '855' },
  C31: { name: 'Cameroon', code: '237' },
  C32: { name: 'Canada', code: '1' },
  C33: { name: 'Cape Verde', code: '238' },
  C34: { name: 'Central African Rep', code: '236' },
  C35: { name: 'Chad', code: '235' },
  C36: { name: 'Chile', code: '56' },
  C37: { name: 'China', code: '86' },
  C38: { name: 'Colombia', code: '57' },
  C39: { name: 'Comoros', code: '26\\9' },
  C40: { name: 'Congo', code: '242' },
  C41: { name: 'Congo {Democratic Rep}', code: '243' },
  C42: { name: 'Costa Rica', code: '506' },
  C43: { name: 'Croatia', code: '385' },
  C44: { name: 'Cuba', code: '53' },
  C45: { name: 'Cyprus', code: '357' },
  C46: { name: 'Czech Republic', code: '420' },
  C47: { name: 'Denmark', code: '45' },
  C48: { name: 'Djibouti', code: '253' },
  C49: { name: 'Dominica', code: '1767' },
  C50: { name: 'Dominican Republic', code: '180\\9' },
  C51: { name: 'East Timor', code: '670' },
  C52: { name: 'Ecuador', code: '5\\93' },
  C53: { name: 'Egypt', code: '20' },
  C54: { name: 'El Salvador', code: '503' },
  C55: { name: 'Equatorial Guinea', code: '240' },
  C56: { name: 'Eritrea', code: '2\\91' },
  C57: { name: 'Estonia', code: '372' },
  C58: { name: 'Ethiopia', code: '251' },
  C59: { name: 'Fiji', code: '67\\9' },
  C60: { name: 'Finland', code: '358' },
  C61: { name: 'France', code: '33' },
  C62: { name: 'Gabon', code: '241' },
  C63: { name: 'Gambia', code: '220' },
  C64: { name: 'Georgia', code: '\\9\\95' },
  C65: { name: 'Germany', code: '4\\9' },
  C66: { name: 'Ghana', code: '233' },
  C67: { name: 'Greece', code: '30' },
  C68: { name: 'Grenada', code: '1473' },
  C69: { name: 'Guatemala', code: '502' },
  C70: { name: 'Guinea', code: '224' },
  C71: { name: 'Guinea-Bissau', code: '245' },
  C72: { name: 'Guyana', code: '5\\92' },
  C73: { name: 'Haiti', code: '50\\9' },
  C74: { name: 'Honduras', code: '504' },
  C75: { name: 'Hungary', code: '36' },
  C76: { name: 'Iceland', code: '354' },
  C77: { name: 'India', code: '\\91' },
  C78: { name: 'Indonesia', code: '62' },
  C79: { name: 'Iran', code: '\\98' },
  C80: { name: 'Iraq', code: '\\964' },
  C81: { name: 'Ireland {Republic}', code: '353' },
  C82: { name: 'Israel', code: '\\972' },
  C83: { name: 'Italy', code: '3\\9' },
  C84: { name: 'Ivory Coast', code: '225' },
  C85: { name: 'Jamaica', code: '1876' },
  C86: { name: 'Japan', code: '81' },
  C87: { name: 'Jordan', code: '\\962' },
  C88: { name: 'Kazakhstan', code: '7' },
  C89: { name: 'Kenya', code: '254' },
  C90: { name: 'Kiribati', code: '686' },
  C91: { name: 'Korea North', code: '850' },
  C92: { name: 'Korea South', code: '82' },
  C93: { name: 'Kosovo', code: '383' },
  C94: { name: 'Kuwait', code: '\\965' },
  C95: { name: 'Kyrgyzstan', code: '\\9\\96' },
  C96: { name: 'Laos', code: '856' },
  C97: { name: 'Latvia', code: '371' },
  C98: { name: 'Lebanon', code: '\\961' },
  C99: { name: 'Lesotho', code: '266' },
  C100: { name: 'Liberia', code: '231' },
  C101: { name: 'Libya', code: '218' },
  C102: { name: 'Liechtenstein', code: '423' },
  C103: { name: 'Lithuania', code: '370' },
  C104: { name: 'Luxembourg', code: '352' },
  C105: { name: 'Macedonia', code: '38\\9' },
  C106: { name: 'Madagascar', code: '261' },
  C107: { name: 'Malawi', code: '265' },
  C108: { name: 'Malaysia', code: '60' },
  C109: { name: 'Maldives', code: '\\960' },
  C110: { name: 'Mali', code: '223' },
  C111: { name: 'Malta', code: '356' },
  C112: { name: 'Marshall Islands', code: '6\\92' },
  C113: { name: 'Mauritania', code: '222' },
  C114: { name: 'Mauritius', code: '230' },
  C115: { name: 'Mexico', code: '52' },
  C116: { name: 'Micronesia', code: '6\\91' },
  C117: { name: 'Moldova', code: '373' },
  C118: { name: 'Monaco', code: '377' },
  C119: { name: 'Mongolia', code: '\\976' },
  C120: { name: 'Montenegro', code: '382' },
  C121: { name: 'Morocco', code: '212' },
  C122: { name: 'Mozambique', code: '258' },
  C123: { name: 'Myanmar, {Burma}', code: '\\95' },
  C124: { name: 'Namibia', code: '264' },
  C125: { name: 'Nauru', code: '674' },
  C126: { name: 'Nepal', code: '\\977' },
  C127: { name: 'Netherlands', code: '31' },
  C128: { name: 'New Zealand', code: '64' },
  C129: { name: 'Nicaragua', code: '505' },
  C130: { name: 'Niger', code: '227' },
  C131: { name: 'Nigeria', code: '234' },
  C132: { name: 'Norway', code: '47' },
  C133: { name: 'Oman', code: '\\968' },
  C134: { name: 'Pakistan', code: '\\92' },
  C135: { name: 'Palau', code: '680' },
  C136: { name: 'Panama', code: '507' },
  C137: { name: 'Papua New Guinea', code: '675' },
  C138: { name: 'Paraguay', code: '595' },
  C139: { name: 'Peru', code: '51' },
  C140: { name: 'Philippines', code: '63' },
  C141: { name: 'Poland', code: '48' },
  C142: { name: 'Portugal', code: '351' },
  C143: { name: 'Qatar', code: '\\974' },
  C144: { name: 'Romania', code: '40' },
  C145: { name: 'Russian Federation', code: '7' },
  C146: { name: 'Rwanda', code: '250' },
  C147: { name: 'St Kitts & Nevis', code: '186\\9' },
  C148: { name: 'St Lucia', code: '1758' },
  C149: { name: 'Saint Vincent & the Grenadines', code: '1784' },
  C150: { name: 'Samoa', code: '685' },
  C151: { name: 'San Marino', code: '378' },
  C152: { name: 'Sao Tome & Principe', code: '23\\9' },
  C153: { name: 'Saudi Arabia', code: '\\966' },
  C154: { name: 'Senegal', code: '221' },
  C155: { name: 'Serbia', code: '381' },
  C156: { name: 'Seychelles', code: '248' },
  C157: { name: 'Sierra Leone', code: '232' },
  C158: { name: 'Singapore', code: '65' },
  C159: { name: 'Slovakia', code: '421' },
  C160: { name: 'Slovenia', code: '386' },
  C161: { name: 'Solomon Islands', code: '677' },
  C162: { name: 'Somalia', code: '252' },
  C163: { name: 'South Africa', code: '27' },
  C164: { name: 'South Sudan', code: '211' },
  C165: { name: 'Spain', code: '34' },
  C166: { name: 'Sri Lanka', code: '\\94' },
  C167: { name: 'Sudan', code: '24\\9' },
  C168: { name: 'Suriname', code: '5\\97' },
  C169: { name: 'Swaziland', code: '268' },
  C170: { name: 'Sweden', code: '46' },
  C171: { name: 'Switzerland', code: '41' },
  C172: { name: 'Syria', code: '\\963' },
  C173: { name: 'Taiwan', code: '886' },
  C174: { name: 'Tajikistan', code: '\\9\\92' },
  C175: { name: 'Tanzania', code: '255' },
  C176: { name: 'Thailand', code: '66' },
  C177: { name: 'Togo', code: '228' },
  C178: { name: 'Tonga', code: '676' },
  C179: { name: 'Trinidad & Tobago', code: '1868' },
  C180: { name: 'Tunisia', code: '216' },
  C181: { name: 'Turkey', code: '\\90' },
  C182: { name: 'Turkmenistan', code: '\\9\\93' },
  C183: { name: 'Tuvalu', code: '688' },
  C184: { name: 'Uganda', code: '256' },
  C185: { name: 'Ukraine', code: '380' },
  C186: { name: 'United Arab Emirates', code: '\\971' },
  C188: { name: 'United States', code: '1' },
  C189: { name: 'Uruguay', code: '598' },
  C190: { name: 'Uzbekistan', code: '\\9\\98' },
  C191: { name: 'Vanuatu', code: '678' },
  C192: { name: 'Vatican City', code: '3\\9' },
  C193: { name: 'Venezuela', code: '58' },
  C194: { name: 'Vietnam', code: '84' },
  C195: { name: 'Yemen', code: '\\967' },
  C196: { name: 'Zambia', code: '260' },
  C197: { name: 'Zimbabwe', code: '263' }
};