import { useCallback, useState } from "react";

import { InputSelectChange } from "#/atoms";

export interface InputSelectHookProps {
  checked: boolean;
  error: string | null;
  onChange: InputSelectChange;
  setError: (message: string | null) => void;
  setChecked: (checked: boolean) => void;
  value: string;
}

const useInputSelect = (initialValue?: string, isChecked?: boolean): InputSelectHookProps => {
  const [checked, setChecked] = useState<boolean>(isChecked ?? false);
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState<string>(initialValue || '');

  const onChange: InputSelectChange = useCallback((c, v) => {
    setChecked(c);
    setValue(v);

  }, []);

  return {
    checked,
    error,
    onChange,
    setChecked,
    setError,
    value,
  }
};


export default useInputSelect;
