import { useCallback, useState } from "react";
import useDatepicker, { DatepickerHookProps } from "../useDatepicker";
import useInput, { InputHookProps } from "../useInput";
import useInputSelect, { InputSelectHookProps } from "../useInputSelect";
import useSelect, { SelectHookProps } from "../useSelect";
import useTimepicker, { TimepickerHookProps } from "../useTimepicker";

import { CreateOrderParam } from "@/api/order/interface";
import { useApi } from "@/api";

export interface FieldsReturn {
  addressString: InputHookProps;
  flat: InputHookProps;
  doorNumber: InputHookProps;

  appointmentDate: DatepickerHookProps;
  appointmentTime: TimepickerHookProps;
  appointmentDuration: InputHookProps;
  contactVendorDirectly: InputSelectHookProps;
  notifyViaSMS: InputSelectHookProps;

  poNumber: InputHookProps;
  bedrooms: InputHookProps;
  floorArea: InputHookProps;
  parking: InputHookProps;
  virtualTourPartial: InputSelectHookProps;

  keys: InputSelectHookProps;
  keysDate: DatepickerHookProps;
  keysTime: TimepickerHookProps;
  keysTimeOption: SelectHookProps;
  keysWith: SelectHookProps;
  keysWithDetail: InputHookProps;
  keyNotes: InputHookProps;

  allowFreelance: InputSelectHookProps;
  orderOfferPrice: InputHookProps;
  preferredWorker: InputHookProps;

  orderExtraChargeDescription: InputHookProps;
  orderExtraChargePriceExVat: InputHookProps;

  orderState: SelectHookProps;

  daysToPayInvoices: InputHookProps;
  email: InputHookProps;
  firstName: InputHookProps;
  lastName: InputHookProps;
  login: InputHookProps;
  phoneNumber: InputHookProps;

  fieldsHaveChanges: (data: CreateOrderParam) => boolean;
  resetFields: () => void;
  storeFieldsData: (data: CreateOrderParam) => void;
  updateFields: () => void;
}

const useFields = (): FieldsReturn => {
  const { order } = useApi();
  const [fieldsData, setFieldsData] = useState('');
  
  const addressString = useInput();
  const flat = useInput();
  const doorNumber = useInput();

  const appointmentDate = useDatepicker();
  const appointmentTime = useTimepicker();
  const appointmentDuration = useInput();
  const contactVendorDirectly = useInputSelect();
  const notifyViaSMS = useInputSelect();
 
  const poNumber = useInput();
  const bedrooms = useInput();
  const floorArea = useInput();
  const parking = useInput();
  const virtualTourPartial = useInputSelect();

  const keys = useInputSelect();
  const keysDate = useDatepicker();
  const keysTime = useTimepicker();
  const keysTimeOption = useSelect('Any time');
  const keysWith = useSelect('Agent');
  const keysWithDetail = useInput();
  const keyNotes = useInput();

  const allowFreelance = useInputSelect();
  const orderOfferPrice = useInput();
  const preferredWorker = useInput();

  const orderExtraChargeDescription = useInput();
  const orderExtraChargePriceExVat = useInput();

  const orderState = useSelect('0');

  const daysToPayInvoices = useInput();
  const email = useInput();
  const firstName = useInput();
  const lastName = useInput();
  const login = useInput();
  const phoneNumber = useInput();

  const storeFieldsData = useCallback((data: CreateOrderParam) => {
    setFieldsData(JSON.stringify(data));
  }, []);

  const fieldsHaveChanges = useCallback((data: CreateOrderParam) => {
    const newData = JSON.stringify(data);

    return newData === fieldsData;
  }, [fieldsData]);
  
  const resetFields = useCallback(() => {
    addressString.setValue('');
    flat.setValue('');
    doorNumber.setValue('');

    appointmentDate.setValue(null);
    appointmentTime.setValue('');
    appointmentDuration.setValue('');
    contactVendorDirectly.setChecked(false);
    notifyViaSMS.setChecked(false);

    poNumber.setValue('');
    bedrooms.setValue('');
    floorArea.setValue('');
    parking.setValue('');
    virtualTourPartial.setChecked(false);

    keys.setChecked(false);
    keysDate.setValue(null);
    keysTime.setValue('');
    keysTimeOption.setValue('Any time');
    keysWith.setValue('Agent');
    keysWithDetail.setValue('');
    keyNotes.setValue('');

    orderState.setValue('0');

    allowFreelance.setChecked(false);
    orderOfferPrice.setValue('');
    preferredWorker.setValue('');

    daysToPayInvoices.setValue('');
    email.setValue('');
    firstName.setValue('');
    lastName.setValue('');
    login.setValue('');
    phoneNumber.setValue('');
  }, []);

  const updateFields = useCallback(() => {
    const { orderDetails } = order;

    if (orderDetails) {
      addressString.setValue(orderDetails.propertyAddress.addressString || '');
      flat.setValue(orderDetails.propertyAddress.flat || '');
      doorNumber.setValue(orderDetails.propertyAddress.doorNumber || '');
  
      const appointmentDateTimeValue = new Date(orderDetails.common.appointmentDateTime);
      appointmentDate.setValue(appointmentDateTimeValue);
      appointmentTime.setValue(`${appointmentDateTimeValue.getHours()}:${appointmentDateTimeValue.getMinutes()}`);
      appointmentDuration.setValue(`${orderDetails.common.appointmentDuration}`);
      contactVendorDirectly.setChecked(orderDetails.common.contactVendorDirectly);
      notifyViaSMS.setChecked(orderDetails.common.notifyViaSMS);
  
      poNumber.setValue(`${orderDetails.poNumber}`);
      bedrooms.setValue(`${orderDetails.bedrooms}`);
      floorArea.setValue(`${orderDetails.floorArea}`);
      parking.setValue(orderDetails.parking);
      virtualTourPartial.setChecked(orderDetails.virtualTourPartial);
  
      const [keysDateValue, keysTimeValue] = orderDetails.keysTime.split(',')
      keys.setChecked(orderDetails.keys);
      keysDate.setValue(keysDateValue ? new Date(keysDateValue) : null);
      keysTime.setValue(keysTimeValue ? keysTimeValue.trim() : '');
      keysTimeOption.setValue(orderDetails.keysTimeOption || 'Any time');
      keysWith.setValue(orderDetails.keysWith || 'Agent');
      keysWithDetail.setValue(orderDetails.keysWithDetail);
      keyNotes.setValue(orderDetails.notes);

      orderOfferPrice.setValue(`${orderDetails.orderOfferPrice}`);

      orderState.setValue(`${orderDetails.orderState}`);

      daysToPayInvoices.setValue(`${orderDetails.accountDetails.daysToPayInvoices}`);
      email.setValue(orderDetails.accountDetails.accountsEmailAddress);
      firstName.setValue(orderDetails.accountDetails.firstName);
      lastName.setValue(orderDetails.accountDetails.lastName);
      login.setValue(orderDetails.accountDetails.name);
      phoneNumber.setValue(orderDetails.accountDetails.accountPhoneNumber ?? '');
    }
  }, [order.orderDetails]);

  return {
    addressString,
    allowFreelance,
    appointmentDate,
    appointmentDuration,
    appointmentTime,
    bedrooms,
    contactVendorDirectly,
    daysToPayInvoices,
    doorNumber,
    email,
    fieldsHaveChanges,
    firstName,
    flat,
    floorArea,
    keyNotes,
    keys,
    keysDate,
    keysTime,
    keysTimeOption,
    keysWith,
    keysWithDetail,
    lastName,
    login,
    notifyViaSMS,
    orderExtraChargeDescription,
    orderExtraChargePriceExVat,
    orderOfferPrice,
    orderState,
    parking,
    phoneNumber,
    poNumber,
    preferredWorker,
    resetFields,
    storeFieldsData,
    updateFields,
    virtualTourPartial
  }
}

export default useFields;