import { Alert, Button, ButtonsGroup, GlobalStyles, Heading, InputSelect, InputText, Link, Select, Typo } from "#/atoms";
import { DESKTOP_SPACING, HPOSITION, NATURAL, PRIMARY_BLUE } from "@/theme";
import { FC, memo, useCallback, useState } from "react";
import { StyledImage, StyledLoginForm, StyledLogo, StyledSignup, StyledSignupForm, StyledSignupImage } from "./Signup.styled";
import { useInput, useInputSelect, useSelect } from "@/hooks";

import { PhotoplanLogo } from "#/atoms";
import { SignupProps } from "./Signup.interface";
import bgImage from "@/assets/bg.jpeg";
import { countryList } from "@/helpers";

const Login: FC<SignupProps> = ({ onLogin, onSignup, onSignupWorker }) => {
  const [error, setError] = useState<string | null>(null);
  const firstname = useInput();
  const lastname = useInput();
  const login = useInput();
  const password = useInput();
  const confirmPassword = useInput();
  const companyName = useInput();
  const branchName = useInput();
  const phone = useInput();
  const promoCode = useInput();
  const countryId = useSelect('0');
  const userType = useSelect('0');
  const agree = useInputSelect('', false);

  const getIsValid = () => {
    return !!firstname.value && !!lastname.value && !!login.value && !!password.value && !!confirmPassword.value && !!companyName.value && !!branchName.value && !!phone.value && !!countryId.value && !!userType.value;
  }

  const handleRegister = useCallback(() => {
    setError(null);

    if (!agree.checked || !getIsValid()) {
      return;
    }
    
    onSignup({
      branchName: branchName.value,
      companyName: companyName.value,
      countryId: parseInt(countryId.value, 10),
      email: login.value,
      firstName: firstname.value,
      lastName: lastname.value,
      password: password.value,
      passwordConfirm: confirmPassword.value,
      phone: phone.value,
      promoCode: promoCode.value,
      type: '0'
    });
  }, [firstname, lastname, login, password, confirmPassword, userType, companyName, branchName, phone, promoCode, countryId, agree]);
  
  return (
    <>
      <GlobalStyles backgroundColor={NATURAL.A} />
      {error && <Alert type="error">{error}</Alert>}
      <StyledSignup>
        <StyledLogo>
          <PhotoplanLogo color={NATURAL.K} />
        </StyledLogo>
        <StyledSignupForm>
          <Heading scale="h2" fontColor={NATURAL.K}>New Customer</Heading>
          <InputText type="text" label="First name" value={firstname.value} onChange={firstname.onChange} />
          <InputText type="text" label="Last name" value={lastname.value} onChange={lastname.onChange} />
          <InputText type="email" label="Email" value={login.value} onChange={login.onChange} />
          <InputText type="password" label="Password" value={password.value} onChange={password.onChange} />
          <InputText type="password" label="Confirm Password" value={confirmPassword.value} onChange={confirmPassword.onChange} />
          <InputText type="text" label="Company Name" value={companyName.value} onChange={companyName.onChange} />
          <InputText type="text" label="Branch Name" value={branchName.value} onChange={branchName.onChange} />
          <InputText type="tel" label="Phone" value={phone.value} onChange={phone.onChange} />
          <Select label="Country"
              options={Object.keys(countryList).map((k) => { return { id: k.replace("C", ""), label: countryList[k].name} })}
              onChange={countryId.onChange}
              selected={countryId.value}
            />
          <Select label="Customer Type"
              options={[
                { id: '0', label: 'Business' },
                { id: '1', label: 'Personal' },
              ]}
              onChange={userType.onChange}
              selected={userType.value}
            />
          <InputText type="text" label="Promo Code" value={promoCode.value} onChange={promoCode.onChange} />
          <InputSelect
            type="checkbox"
            checked={agree.checked}
            label={(
              <Typo>
                Agree to <Link href="https://www.photoplan.co.uk/terms/" fontColor={PRIMARY_BLUE.DARK} target="_blank">Terms and Conditions</Link>
              </Typo>
            )}
            margin={`${DESKTOP_SPACING.XXL}px 0 0`}
            onChangeValue={agree.onChange}
            value={agree.value}
          />
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
            <Button state={getIsValid()  ? 'default' : 'disabled'} onClick={handleRegister}>Register</Button>
          </ButtonsGroup>
        </StyledSignupForm>
      </StyledSignup>
      <StyledImage>
        <StyledLoginForm>
          <Heading scale="h1" fontColor={NATURAL.A}>Already have an account?</Heading>
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth margin="0 0 40px">
            <Button onClick={onLogin}>Login</Button>
          </ButtonsGroup>
          <Heading scale="h1" fontColor={NATURAL.A}>Register as worker instead?</Heading>
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth>
            <Button onClick={onSignupWorker}>Register as worker</Button>
          </ButtonsGroup>
        </StyledLoginForm>
        <StyledSignupImage src={bgImage} />
      </StyledImage>
    </>
  );
}

export default memo(Login);
