import { Loading, Notification } from "#/atoms";

import { BigCalendar } from "@/components/pages/BigCalendar";
import { useApi } from "@/api";


const BigCalendarView = () => {

    const { errorMessage, loading, order } = useApi()

    const onDatesSet = async (start: Date, end: Date) => {
        const res = await order.getAppointments(start, end);

        return res;
    }

    return (
      <>
        {loading && <Loading />}
        {errorMessage && <Notification>{errorMessage}</Notification>}
        <BigCalendar onDatesSet={onDatesSet}></BigCalendar>
      </>
    );
}

export default BigCalendarView;