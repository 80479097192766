import { Customer, OnSaveCustomer } from "#/pages";

import { useApi } from "@/api";
import { useCallback } from "react";
import { useNavigate } from 'react-router-dom';

const CustomerView = () => {
  const { customer } = useApi();
  const navigate = useNavigate();

  const handleSave: OnSaveCustomer = useCallback(async (
    newCustomer,
    newAddress,
    newContact,
    sendEmailNotification
  ) => {
    customer.createCustomer({
      accountDetails: {
        accountType: '0',
        confirmPassword: newCustomer.confirmPassword,
        email: newCustomer.email,
        firstName: newCustomer.firstName,
        isActive: true,
        lastName: newCustomer.lastName,
        password: newCustomer.password,
        sendEmailNotification,
      },
      address: {
        active: newCustomer.active,
        area: newAddress.area,
        county: newAddress.county,
        countryId: newAddress.countryId,
        customerCode: newCustomer.code,
        houseName: newAddress.houseName,
        houseNumber: newAddress.houseNumber,
        isCardPayment: newAddress.isCardPayment,
        isLowQuality: newAddress.isLowQuality,
        name: newCustomer.firstName,
        postcode: newAddress.postcode,
        road: newAddress.road,
        shouldRemoveData: newAddress.shouldRemoveData,
        tax: newCustomer.vat,
        town: newAddress.town,
      },
      contacts: [{
        contactId: '',
        customerId: '',
        email: newContact.email,
        name: newContact.name,
        phone1: newContact.phone1,
        phone2: newContact.phone2 || '',
      }],
    }).then(() => {
      navigate('/customers', { replace: true });
    });
  }, []);

  return (
    <>
      <Customer onSave={handleSave} />
    </>
  );
}

export default CustomerView;