import { BigCalendarEvent, BigCalendarProps } from "./BigCalendar.interface";
import { FC, memo } from "react";
import { Heading, PageHeader, PageWrapper } from "#/atoms";
import { Appointment } from "@/types";
import FullCalendar from '@fullcalendar/react'
import { NATURAL } from "@/theme";
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';

import "./hack.css";

const BigCalendar: FC<BigCalendarProps> = ({
  calendarId,
  onDatesSet
}) => {

  const navigate = useNavigate();

  const mapToCalendarEvent = (ap : Appointment[]) => {
      return ap.map((a: Appointment) : BigCalendarEvent => {
          const date = a.WorkDate + '.000Z';

          const services = a.Services.reduce((acc: string | number, curr: { Name: string; }) => acc  + (acc ? ", " : "") + curr.Name, "");

          return {
              description: `<strong>Customer:</strong> ${a.CustomerName}<br> <strong>Address:</strong> ${a.PropertyAddress}<br> <strong>Services:</strong> ${services}`,
              end: new Date(new Date(date).getTime() + 1800000).toISOString(),
              id: a.OrderId,
              start: date,
              title: a.OrderId,
          }
      })
  }  

  const calendarRef = useRef<FullCalendar>(null);

  const eventIdCache : string[] = [];

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Calendar {calendarId}</Heading>
      </PageHeader>
      <div className="height600">
        <FullCalendar
          ref={calendarRef} 
          plugins={[ dayGridPlugin, timeGridPlugin, listPlugin ]}
          headerToolbar= {{ center: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek' }}
          initialView='dayGridMonth'
          dayMaxEvents={6}
          firstDay={1}
          events={[]}
          eventDidMount={(info) =>{
            const span = document.createElement('span');
            span.classList.add('tooltiptext');
            span.innerHTML = info.event.extendedProps.description;
            info.el.appendChild(span);
          }}
          eventClick={(info) => {
            navigate(`/order-view-backend/order=${info.event.title}`, { replace: false });
          }}
          datesSet={async (e) => {
            const events = await onDatesSet(e.start, e.end);
            const mappedEvents = mapToCalendarEvent(events);
            mappedEvents.forEach((ev: BigCalendarEvent) => {
              if (!eventIdCache.includes(ev.id)){
                eventIdCache.push(ev.id);
                calendarRef.current?.getApi().addEvent(ev);
              }
            });
          }}
        />
      </div>
    </PageWrapper>
  );
};

export default memo(BigCalendar);