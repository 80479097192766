import { Accordion, Box, Button, Card, Grid, InputSelect, InputText, Typo } from "#/atoms";
import { DESKTOP_SPACING, FONT_WEIGHT } from "@/theme";
import { FC, memo } from "react";

import { FreelanceOfferCardProps } from "./FreelanceOffer.interface";
import { HeadingIcon } from "#/molecules";
import { StyledSmallButtons } from "../OrderStatus/OrderStatus.styled";

const FreelanceOfferCard: FC<FreelanceOfferCardProps> = ({
  accordionState = 'open',
  allowFreelance,
  onChangeRole,
  orderOfferPrice,
  preferredWorker,
}) => {
  return (
    <Card>
      <Accordion
        header={<HeadingIcon icon="wallet" title="Freelance Offer" />}
        headerMargin={`${DESKTOP_SPACING.XXXL}px`}
        showArrow
        state={accordionState}
      >
        <InputSelect
          type="checkbox"
          checked={allowFreelance.checked}
          label="Allow Freelance Offers"
          name="allowFreelance"
          value="allowFreelance"
          onChangeValue={allowFreelance.onChange}
        />
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 40%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          
        <InputText
            type="number"
            label="Order Offer Price"
            value={orderOfferPrice.value}
            onChange={orderOfferPrice.onChange}
          />
          <Box>
            <Typo weight={FONT_WEIGHT.BOLD} margin="0">Booking Coordinator</Typo>
            {preferredWorker && <Typo  margin={`${DESKTOP_SPACING.SM}px 0 0`}>{preferredWorker.name}</Typo>}
          </Box>
          <StyledSmallButtons>
            <Button actionType='outline' buttonSize="md" onClick={onChangeRole}>Change Role</Button>
          </StyledSmallButtons>
        </Grid>
      </Accordion>
    </Card>
  );
};

export default memo(FreelanceOfferCard);