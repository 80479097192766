import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { Loading, Notification } from "#/atoms";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Login } from "#/pages";
import { useApi } from "@/api";

interface LocationState {
  from: {
    pathname: string;
  };
}

const LoginView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { errorMessage, loading, session } = useApi()
  
  const from = (location.state as LocationState)?.from?.pathname || "/";

  useEffect(() => {
    if (session.user) {
      navigate('/', { replace: true })
    }
  }, [session]);

  const handleSubmit = useCallback((user: string, password: string, rememberMe: boolean) => {
    session.login(user, password, rememberMe).then(() => {
      navigate(from, { replace: true });
    });
  }, []);

  const handlePasswordReset = useCallback((email: string) => {
    session.resetPassword(email);
  }, []);

  const handleSignup = useCallback(() => {
    navigate('/register', { replace: true });
  }, []);

  const handleGoogleloginSuccess = useCallback((response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('getAuthResponse' in response) {
      const idToken = response.getAuthResponse().id_token;
  
      session.loginGoogle(idToken)
    }
  }, []);

  return (
    <>
      {loading && <Loading />}
      {errorMessage && <Notification>{errorMessage}</Notification>}
      <Login onSubmit={handleSubmit} onSignup={handleSignup} onGoogleSuccess={handleGoogleloginSuccess} onPasswordReset={handlePasswordReset} />
    </>
  );
}

export default LoginView;