import { ApiCreateOrder, ApiMaintainer, ApiOrder, ApiOrderDetails, ApiOrderFile, ApiOrderFilterParams, ApiOrderNote, ApiService, ApiUpdateOrder } from "./interface.api";
import { CreateOrderParam, OrderFilterParams, ServiceParam, UpdateOrderParam } from "./interface";
import { Maintainer, Order, OrderDetails, OrderFile, OrderNote } from "@/types";
import { getOrderStateEnum, getOrderStateNumber } from "@/helpers";

export const mapOrderFilter = (filter?: OrderFilterParams): ApiOrderFilterParams => {
  return {
    CustomerCode: filter?.customerCode || '',
    CustomerEmail: filter?.customerEmail || '',
    CustomerName: filter?.customerName || '',
    DateFrom: filter?.dateFrom || '',
    DateOrderTaken: filter?.dateOrderTaken || '',
    DateTo: filter?.dateTo || '',
    DateWorkToBeDone: filter?.dateWorkToBeDone || '',
    MaintainerId: filter?.maintainerId || '',
    OrderId: filter?.orderId || '',
    OrderRef: filter?.orderRef || '',
    OrderState: filter?.orderState ? getOrderStateNumber(filter.orderState) : null,
    PropertyAddress: filter?.propertyAddress || '',
    WorkerId: filter?.workerId || ''
  }
};

export const mapApiOrder = (api: ApiOrder[]): Order[] => {
  return api.map((res) => ({
    badge: '',
    companyName: res.CustomerName,
    date: res.WorkDate,
    id: `${res.OrderId}`,
    location: res.PropertyAddress,
    logoUrl: '',
    price: Number(res.Cost),
    services: res.Services.map((service) => ({
      id: '',
      image: service.ImageURL,
      name: service.Name,
      price: 0,
    })),
    state: getOrderStateEnum(res.OrderState),
    status: res.OrderStatus,
    title: res.Ref,
  }))
};

export const mapServiceParam = (service: ServiceParam): ApiService => {
  return {
    ImageURL: service.imageURL,
    Name: service.name,
    Rejected: service.rejected,
  }
}

export const mapCreateOrderParam = (order: CreateOrderParam): ApiCreateOrder => {
  return {
    Bedrooms: order.bedrooms,
    Common: {
      AppointmentDateTime: order.common.appointmentDateTime,
      AppointmentDuration: order.common.appointmentDuration,
      ContactVendorDirectly: order.common.contactVendorDirectly,
      NotifyViaSMS: order.common.notifyViaSMS,
    },
    Contacts: order.contacts.map((contact) => ({
      Email: contact.email,
      Name: contact.name,
      Phone1: contact.phone1,
      Phone2: contact.phone2,
      Type: contact.type || 2,
    })),
    CustomerId: order.customerId,
    FloorArea: order.floorArea,
    Keys: order.keys,
    KeysTime: order.keysTime,
    KeysTimeOption: order.keysTimeOption,
    KeysWith: order.keysWith,
    KeysWithDetail: order.keysWithDetail,
    Notes: order.notes,
    OrderNotes: order.orderNotes.map((note) => ({
      Content: note.content,
    })),
    OrderOfferPrice: order.orderOfferPrice,
    Parking: order.parking,
    PoNumber: order.poNumber || '',
    PreferredWorker: order.preferredWorker,
    PropertyAddress: {
      AddressString: order.propertyAddress.addressString,
      DoorNumber: order.propertyAddress.doorNumber,
      Flat: order.propertyAddress.flat,
    },
    UseOrderOffer: order.useOrderOffer,
    VirtualTourPartial: order.virtualTourPartial
  }
}

export const mapUpdateOrderParam = (order: UpdateOrderParam): ApiUpdateOrder => {
  return {
    ...mapCreateOrderParam(order),
    Maintainer: {
      ApplicationUserId: order.maintainer.applicationUserId,
      FirstName: order.maintainer.firstName,
      Id: order.maintainer.id,
      LastName: order.maintainer.lastName,
    },
    OrderId: order.orderId,
    OrderState: order.orderState,
    Services: order.services.map((service) => ({
      AmendedDateTime: service.amendedDateTime,
      Amount: service.amount,
      ApplicationUserId: service.applicationUserId,
      ApplicationUserName: service.applicationUserName,
      AppointmentDate: service.appointmentDate,
      AppointmentDuration: service.appointmentDuration,
      Complete: service.complete,
      CreatedDateTime: service.createdDateTime,
      Description: service.description,
      DraftFiles: service.draftFiles,
      DraftUploadFiles: service.draftUploadFiles.map((file) => ({
        FilePaymentStatus: file.filePaymentStatus,
        FileStatus: file.fileStatus,
        FullURL: file.fullURL,
        IsUpsell: file.isUpsell,
        ThumbURL: file.thumbURL,
        UploadFileId: file.uploadFileId,
        UpsellPrice: file.upsellPrice,
      })),
      ExternalLink: service.externalLink,
      Files: service.files,
      HdrSourceFiles: service.hdrSourceFiles.map((file) => ({
        FullURL: file.fullURL,
        HdrSourceFileId: file.hdrSourceFileId,
        ThumbURL: file.thumbURL,
      })),
      ImageURL: service.imageURL,
      IsExtraService: service.isExtraService,
      Label: service.label,
      Name: service.name,
      NotificationMessage: service.notificationMessage,
      OrderServiceId: service.orderServiceId,
      Price: service.price,
      RejectReason: service.rejectReason,
      Rejected: service.rejected,
      ServiceAdditionalFields: service.serviceAdditionalFields,
      ShowConfirm: service.showConfirm,
      ShowSendMessage: service.showSendMessage,
      SourceFiles: service.sourceFiles.map((file) => ({
        FilePaymentStatus: file.filePaymentStatus,
        FileStatus: file.fileStatus,
        FullURL: file.fullURL,
        IsUpsell: file.isUpsell,
        ThumbURL: file.thumbURL,
        UploadFileId: file.uploadFileId,
        UpsellPrice: file.upsellPrice,
      })),
      Type: service.type,
      UploadFiles: service.uploadFiles.map((file) => ({
        FilePaymentStatus: file.filePaymentStatus,
        FileStatus: file.fileStatus,
        FullURL: file.fullURL,
        IsUpsell: file.isUpsell,
        ThumbURL: file.thumbURL,
        UploadFileId: file.uploadFileId,
        UpsellPrice: file.upsellPrice,
      })),
    })),
  };
}

export const mapApiOrderFile = (file: ApiOrderFile): OrderFile => {
  return {
    filePaymentStatus: file.FilePaymentStatus,
    fileStatus: file.FileStatus,
    fullURL: file.FullURL,
    isUpsell: file.IsUpsell,
    thumbURL: file.ThumbURL,
    uploadFileId: file.UploadFileId,
    upsellPrice: file.UpsellPrice,
  }
}

export const mapApiOrderFiles = (files: ApiOrderFile[]): OrderFile[] => {
  return files.map((file) => mapApiOrderFile(file));
}

export const mapApiOrderNotes = (notes: ApiOrderNote[]): OrderNote[] => {
  return notes.map((note) => ({
    content: note.Content,
    created: note.CreatedDateTime,
    id: `${note.NoteId}`,
    owner: note.OwnerName,
  }));
}


export const mapApiOrderDetails = (api: ApiOrderDetails, orderNotes: OrderNote[]): OrderDetails => {
  const { AccountDetails, Common, Contacts, OfferedServices, OrderExtraCharge, PropertyAddress, Services } = api;

  return {
    accountDetails: {
      accountPhoneNumber: AccountDetails.AccountPhoneNumber,
      accountsEmailAddress: AccountDetails.AccountsEmailAddress,
      active: AccountDetails.IsActive,
      daysToPayInvoices: AccountDetails.DaysToPayInvoices,
      firstName: AccountDetails.FirstName,
      lastName: AccountDetails.LastName,
      name: AccountDetails.Name,
    },
    bedrooms: api.Bedrooms,
    branchId: api.BranchId,
    common: {
      appointmentDateTime: Common.AppointmentDateTime,
      appointmentDuration: Common.AppointmentDuration,
      contactVendorDirectly: Common.ContactVendorDirectly,
      notifyViaSMS: Common.NotifyViaSMS,
    },
    companyName: api.CompanyName,
    contacts: Contacts.map((contact) => ({
      amendedDateTime: contact.AmendedDateTime,
      createdDateTime: contact.CreatedDateTime,
      email: contact.Email,
      id: Number(contact.OrderContactId),
      name: contact.Name,
      orderId: contact.OrderId,
      phone1: contact.Phone1,
      phone2: contact.Phone2,
      type: contact.Type,
    })),
    createdDateTime: api.CreatedDateTime,
    customerId: api.CustomerId,
    customerUserId: api.CustomerUserId,
    defaultAmount: api.DefaultAmount,
    description: api.Description,
    floorArea: api.FloorArea,
    hasRejections: api.HasRejections,
    id: `${api.OrderId}`,
    keys: api.Keys,
    keysTime: api.KeysTime,
    keysTimeOption: api.KeysTimeOption,
    keysWith: api.KeysWith,
    keysWithDetail: api.KeysWithDetail,
    maintainer: api.Maintainer,
    notes: api.Notes,
    offeredServices: OfferedServices.map((service) => ({
      amendedDateTime: service.AmendedDateTime,
      createdDateTime: service.CreatedDateTime,
      files: service.Files,
      imageURL: service.ImageURL,
      name: service.Name,
      offeredServiceId: service.OfferedServiceId,
      price: service.Price,
      type: service.Type,
      uploadFiles: service.UploadFiles.map((file) => mapApiOrderFile(file)),
    })),
    orderExtraCharge: {
      orderExtraChargeDescription: OrderExtraCharge?.OrderExtraChargeDescription,
      orderExtraChargeId: OrderExtraCharge?.OrderExtraChargeId,
      orderExtraChargePriceExVat: OrderExtraCharge?.OrderExtraChargePriceExVat,
    },
    orderNotes,
    orderOfferPrice: api.OrderOfferPrice,
    orderPrice: api.OrderPrice,
    orderState: api.OrderState,
    parking: api.Parking || '',
    poNumber: api.PoNumber,
    propertyAddress: {
      addressId: PropertyAddress.AddressId,
      addressString: PropertyAddress.AddressString,
      amendedDateTime: PropertyAddress.AmendedDateTime,
      area: PropertyAddress.Area,
      country: PropertyAddress.Country,
      createdDateTime: PropertyAddress.CreatedDateTime,
      doorNumber: PropertyAddress.DoorNumber,
      flat: PropertyAddress.Flat,
      houseName: PropertyAddress.HouseName,
      houseNumber: PropertyAddress.HouseNumber,
      postcode: PropertyAddress.Postcode,
      road: PropertyAddress.Road,
      town: PropertyAddress.Town,
    },
    services: Services.map((service) => ({
      amendedDateTime: service.AmendedDateTime,
      amount: service.Amount,
      applicationUserId: service.ApplicationUserId,
      applicationUserName: service.ApplicationUserName,
      appointmentDate: service.AppointmentDate,
      appointmentDuration: service.AppointmentDuration,
      complete: service.Complete,
      createdDateTime: service.CreatedDateTime,
      description: service.Description,
      draftFiles: service.DraftFiles,
      draftUploadFiles: service.DraftUploadFiles.map((file) => mapApiOrderFile(file)),
      externalLink: service.ExternalLink,
      files: service.Files,
      hdrSourceFiles: service.HdrSourceFiles.map((hdr) => ({
        fullURL: hdr.FullURL,
        hdrSourceFileId: hdr.HdrSourceFileId,
        thumbURL: hdr.ThumbURL,
      })),
      imageURL: service.ImageURL,
      isExtraService: service.IsExtraService,
      label: service.Label,
      name: service.Name,
      notificationMessage: service.NotificationMessage,
      orderServiceId: service.OrderServiceId,
      price: service.Price,
      rejectReason: service.RejectReason,
      rejected: service.Rejected,
      serviceAdditionalFields: service.ServiceAdditionalFields,
      showConfirm: service.ShowConfirm,
      showSendMessage: service.ShowSendMessage,
      sourceFiles: service.SourceFiles.map((file) => mapApiOrderFile(file)),
      type: service.Type,
      uploadFiles: service.UploadFiles.map((file) => mapApiOrderFile(file)),
    })),
    sizeProperty: api.SizeProperty,
    trustedCustomer: api.TrustedCustomer,
    upsellPrice: api.UpsellPrice,
    virtualTourPartial: api.VirtualTourPartial,
  }
}

export const mapApiMaintainers = (api: ApiMaintainer[]): Maintainer[] => {
  return api.map((item) => ({
    email: item.Email,
    firstName: item.FirstName,
    id: item.Id,
    lastName: item.LastName
  }));
}