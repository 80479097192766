import {
  Accordion,
  Box,
  Button,
  ButtonsGroup,
  Card,
  Empty,
  Footer,
  Grid,
  Heading,
  InputSelect,
  InputText,
  Maps,
  PageHeader,
  PageWrapper,
  Select,
  Typo
} from "#/atoms";
import { DESKTOP_SPACING, FONT_SIZE, HPOSITION, NATURAL } from "@/theme";
import { FC, memo } from "react";
import { useInput, useInputSelect, useMaps, useSelect } from "@/hooks";

import { CustomerDetailsProps } from "./CustomerDetails.interface";
import { CustomerLogoCard } from "@/components/molecules/CustomerLogoCard";
import { HeadingIcon } from "#/molecules";
import { NotesCard } from "../Order/Cards";
import { OrderTemplate } from "@/types/orderTemplate";
import { Package } from "#/organisms";
import { StyledOrderTemplates } from "../Order/CustomerAndPackage/CustomerAndPackage.styled";
import { countryList } from "@/helpers";
import { useParams } from "react-router-dom";

const CustomerDetails: FC<CustomerDetailsProps> = ({ 
  customerData,
  logoUrl,
  notes,
  onAddNote,
  onChangePassword,
  onLogoUpload,
  onSaveAccountDetails,
  onSaveDetails }) => {
  const { mapsAddress, onBlur, onChange } = useMaps();

  const { id } = useParams<'id'>();
  const customerId = id ?? '';

  const login = useInput(customerData?.accountDetails.login);
  const email = useInput(customerData?.accountDetails.email);
  const accountPhone = useInput(customerData?.accountDetails.accountPhoneNumber);
  const firstName = useInput(customerData?.accountDetails.firstName);
  const lastName = useInput(customerData?.accountDetails.lastName);
  const daysToPay = useInput(customerData?.accountDetails.daysToPayInvoices?.toString());

  const vat = useInputSelect('vat', customerData?.address.tax);

  const code = useInput(customerData?.address.customerCode);
  const companyName = useInput(customerData?.address.name);
  const houseName = useInput(customerData?.address.houseName);
  const houseNumber = useInput(customerData?.address.houseNumber);
  const road = useInput(customerData?.address.road);
  const area = useInput(customerData?.address.area);
  const town = useInput(customerData?.address.town);
  const county = useInput(customerData?.address.county);
  const postcode = useInput(customerData?.address.postcode);
  const countryId = useSelect('0');
  const active = useInputSelect('active', customerData?.address.active);
  const payByCard = useInputSelect('payByCard', customerData?.address.isCardPayment);
  const useLowQualityThumbnails = useInputSelect('useLowQualityThumbnails', customerData?.address.isLowQuality);
  const scrubData = useInputSelect('scrubData', customerData?.address.shouldRemoveData);

  const contactName = useInput(customerData?.contacts[0].name);
  const contactEmail = useInput(customerData?.contacts[0].email);
  const contactPhone1 = useInput(customerData?.contacts[0].phone1);
  const contactPhone2 = useInput(customerData?.contacts[0].phone2);

  const handleSaveAccountDetails = async () => {
    onSaveAccountDetails({
      AccountPhoneNumber: accountPhone.value,
      CountryId: 1,
      DaysToPayInvoices: parseInt(daysToPay.value, 10),
      DefaultPhotos: 0,
      Email: email.value,
      FirstName: firstName.value,
      Id: customerId,
      LastName: lastName.value,
      UserName: login.value,
      UserUpsellPrice: 0
    });
  };

  const handleChangePassword = async () => {
    onChangePassword(customerId);
  }

  const handleSaveDetails = async () => {
    onSaveDetails(customerId, {
      Area: area.value,
      Country: county.value,
      CountryId: parseInt(countryId.value, 10),
      CustomerCode: code.value,
      HouseName: houseName.value,
      HouseNumber: houseNumber.value,
      IsActive: active.checked,
      IsCardPayment: payByCard.checked,
      IsLowQuality: useLowQualityThumbnails.checked,
      IsTaxAdded: vat.checked,
      Name: companyName.value,
      Postcode: postcode.value,
      Road: road.value,
      ShouldRemoveData: scrubData.checked,
      Town: town.value
    })
  };

  const loadingOrderTemplates = false;
  const orderTemplates : OrderTemplate[] = [{
    dea: true,
    description: 'alma',
    hip: true,
    id: '1234',
    price: 123,
    services: []
  }];

  const groups = [
    {
      groupMatchRegExp: /^(\d)+ photos$/i,
      groupName: 'Photos Only',
    },
    {
      groupMatchRegExp: /^(\d)+ photos & floor plan$/i,
      groupName: 'Photos & Floor Plan',
    },
    {
      groupMatchRegExp: /^(\d)+ photos & floor plan & EPC$/i,
      groupName: 'Photos & Floor Plan & EPC',
      orderTemplates: []
    },
    {
      groupMatchRegExp: /^(\d)+ photos & floor plan & x(\d)+ 360 tour$/i,
      groupName: 'Photos & Floor Plan & 360 Tour',
    },
    {
      groupMatchRegExp: /\S+/i,
      groupName: 'Other',
    }
  ];

  const onSelectOrderTemplate = () => {
    console.log('called');
  };

  const getOrderTemplates = () => {
    if (loadingOrderTemplates) {
      return 'Loading...'
    }

    if (orderTemplates.length > 0) {
      let ot = orderTemplates;

      const data = groups.map((group) => {
        const orderTemplatesGrouped = ot.filter((item) => group.groupMatchRegExp.test(item.description));
        ot = ot.filter((item) => !group.groupMatchRegExp.test(item.description));
        
        return (
          <Package
            groupName={group.groupName}
            icons={[]}
            id={group.groupName}
            key={group.groupName}
            onClick={onSelectOrderTemplate}
            orderTemplates={orderTemplatesGrouped}
          />
        );
      });

      const other = data.pop();
      
      if (other) {
        data.unshift(other);
      }

      return data;
    }

    return <Empty>No packages have been added yet.</Empty>;
  }

  return (
    <>
      <PageWrapper pb="100px">
        <PageHeader>
          <Heading scale="h1" fontColor={NATURAL.K}>Edit Profile</Heading>
        </PageHeader>

        <Card padding="0">
          <Box padding={`${DESKTOP_SPACING.EL}px`}>
            <Accordion
              header={<HeadingIcon icon="contacts" title="Account Details" />}
              onToggle={() => null}
              showArrow
              state="open"
            >
              <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(3, 30%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
                <InputText type="text" label="Login" value={login.value} onChange={login.onChange} />
                <InputText type="email" label="E-mail Address" value={email.value} onChange={email.onChange} />
                <InputText type="tel" label="Account Phone Number" value={accountPhone.value} onChange={accountPhone.onChange} />
                <InputText type="text" label="First Name" value={firstName.value} onChange={firstName.onChange} />
                <InputText type="text" label="Last Name" value={lastName.value} onChange={lastName.onChange} />
                <InputText type="number" label="Days To Pay Invoices" value={daysToPay.value} onChange={daysToPay.onChange} />
              </Grid>
              <ButtonsGroup align={HPOSITION.RIGHT} fullWidth>
                <Button onClick={() => handleSaveAccountDetails() }>
                  Save
                </Button>
                <Button onClick={() => handleChangePassword() }>
                  Change Password
                </Button>
              </ButtonsGroup>
            </Accordion>
          </Box>
        </Card>

        <Card padding="0">
          <Grid gridTemplateColumns="repeat(2, 1fr)">
            <Box padding={`${DESKTOP_SPACING.EL}px`}>
              <HeadingIcon
                icon="location"
                title="Address Details"
              />
              <InputText type="text" label="Company Name" value={companyName.value} onChange={companyName.onChange} />
              <InputText type="text" label="Customer Code" value={code.value} onChange={code.onChange} />
              <InputSelect type="checkbox" checked={vat.checked} label="Customer VAT" value={vat.value} onChangeValue={vat.onChange} />
              <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM} margin="15 0">Office address:</Typo>
              <InputText type="text" label="House Name" value={houseName.value} onChange={houseName.onChange} />
              <InputText type="text" label="Number" value={houseNumber.value} onChange={houseNumber.onChange} />
              <InputText type="text" label="Road" value={road.value} onChange={road.onChange} />
              <InputText type="text" label="Area" value={area.value} onChange={area.onChange} />
              <InputText type="text" label="Town" value={town.value} onChange={town.onChange} />
              <InputText type="text" label="County" value={county.value} onChange={county.onChange} />
              <InputText type="text" label="Postcode" value={postcode.value} onBlur={onBlur} onChange={postcode.onChange} />
              <Select label="Country"
                  options={Object.keys(countryList).map((k) => { return { id: k.replace("C", ""), label: countryList[k].name} })}
                  onChange={countryId.onChange}
                  selected={countryId.value}
                />
              <InputSelect type="checkbox" checked={active.checked} label="Is Active" value={active.value} onChangeValue={active.onChange} />
              <InputSelect type="checkbox" checked={active.checked} label="Pay by Card" value={payByCard.value} onChangeValue={payByCard.onChange} />
              <InputSelect type="checkbox" checked={active.checked} label="Use low quality thumbnails" value={useLowQualityThumbnails.value} onChangeValue={useLowQualityThumbnails.onChange} />
              <InputSelect type="checkbox" checked={active.checked} label="Scrub personal data once order completes" value={scrubData.value} onChangeValue={scrubData.onChange} />
              <ButtonsGroup align={HPOSITION.RIGHT} fullWidth>
                <Button onClick={() => handleSaveDetails() }>
                  Save
                </Button>
              </ButtonsGroup>
            </Box>
            <Box>
              <Maps address={mapsAddress} />
            </Box>
          </Grid>
        </Card>

        <Card padding="0">
          <Box padding={`${DESKTOP_SPACING.EL}px`}>
            <Accordion
              header={<HeadingIcon icon="contacts" title="Contact Details" />}
              onToggle={() => null}
              showArrow
              state="open"
            >
              <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 40%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
                <InputText type="text" label="Contact Name" value={contactName.value} onChange={contactName.onChange} />
                <InputText type="text" label="E-mail Address" value={contactEmail.value} onChange={contactEmail.onChange} />
                <InputText type="text" label="Phone Number 1" value={contactPhone1.value} onChange={contactPhone1.onChange} />
                <InputText type="text" label="Phone Number 2" value={contactPhone2.value} onChange={contactPhone2.onChange} />
              </Grid>
            </Accordion>
          </Box>
        </Card>
      <NotesCard
        notes={notes}
        onAddNote={onAddNote}
      />
      <CustomerLogoCard logoUrl={logoUrl} onFileUpload={onLogoUpload} />
      <StyledOrderTemplates>
        {getOrderTemplates()}
      </StyledOrderTemplates>
      </PageWrapper>
      <Footer>
         <></>
      </Footer>
    </>
  );
};

export default memo(CustomerDetails);
