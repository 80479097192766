import {useEffect, useState } from "react";

import { Dashboard } from "@/components/pages";
import { useApi } from "@/api";
import { DashboardCustomer } from "@/components/pages/DashboardCustomer";


const DashboardView = () => {
  const { dashboard, session } = useApi();
  const [called, setCalled] = useState(false);
  const [userType, setuserType] = useState(3);

  const getDashboardData = async () => {
    if (!called)
     {
      setCalled(true);
        await dashboard.getDashboardData();
     }
  }

  useEffect(() => {

    if (session.hasAdminRole()) {
      setuserType(1);
      getDashboardData();
    }
    else if (session.hasWorkerRole()) {
      setuserType(2);
      getDashboardData();
    }
    else if (session.hasCustomerRole()) {
      getDashboardData();
    }
  }, [dashboard.dashboardData, called]);

  

  return (
    <>
      {userType === 1 &&
        (<Dashboard dashboardData={dashboard.dashboardData} firstName={session?.user?.firstName} />)
      }
      {userType === 2 &&
        (<DashboardCustomer firstName={session?.user?.firstName} />)
      }
      {userType === 3 &&
        (<DashboardCustomer firstName={session?.user?.firstName} />)
      }
    </>
  );
}

export default DashboardView;