import { ApiCreateOrder, ApiMaintainer, ApiOrder, ApiOrderDetails, ApiOrderFilterParams, ApiOrderNote, ApiOrderNoteParams } from "./interface.api";
import { ApiFileDownloadRequestID, ApiHooksProps } from "../interface";
import { Appointment, Order, OrderDetails } from "@/types";
import { CreateOrderParam, OrderApi, OrderFilterParams, ServiceParam, UpdateOrderParam } from "./interface";
import { mapApiMaintainers, mapApiOrder, mapApiOrderDetails, mapApiOrderNotes, mapCreateOrderParam, mapOrderFilter, mapServiceParam, mapUpdateOrderParam } from "./mappers";
import { useCallback, useState } from "react";

import { ApiWorker } from "../worker/interface.api";
import { request } from "../request";
import { workersApiMapper } from "../worker/mappers";

const hook = ({ setError, setLoading, serverPath, token }: ApiHooksProps): OrderApi => {
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [orders, setOrders] = useState<Order[]>([]);
  const [ordersForInvoice, setOrdersForInvoice] = useState<Order[]>([]);
  const [ordersOuttakes, setOrdersOuttakes] = useState<Order[]>([]);
  const [unmaintainedOrdersCount, setUnmaintainedOrdersCount] = useState(0);
  const [orderOuttakesCount, setOrderOuttakesCount] = useState(0);

  const addNote = useCallback(async (orderId: string, content: string) => {
    const path = `${serverPath}/notes/create`;
    const body: ApiOrderNoteParams = {
      Content: content,
      OrderId: orderId,
    }
    request(path, { body, token });
  }, [serverPath, token]);

  const changeTemplate = useCallback(async (orderId: string, templateId: string) => {
    const path = `${serverPath}/order/change-template/`;
    const body = {
      'OrderId': orderId,
      'OrderTemplateId': templateId
    };
    request(path, { body, token });
  }, [serverPath, token]);

  const confirmFiles = useCallback(async (orderId: string, body: string[]) => {
    const path = `${serverPath}/order/confirm-files/${orderId}/`;
    request(path, { body, token });
  }, [serverPath, token]);

  const confirmDraftFiles = useCallback(async (orderId: string, body: string[]) => {
    const path = `${serverPath}/order/confirm-draft-files/${orderId}/`;
    request(path, { body, token });
  }, [serverPath, token]);

  const addDraftFiles = useCallback(async (orderId: string, body: string[]) => {
    const path = `${serverPath}/order/add-draft-files/${orderId}/`;
    request(path, { body, token });
  }, [serverPath, token]);

  const createOfferedService = useCallback(async (orderId: string, service: ServiceParam) => {
    const path = `${serverPath}/order/${orderId}/offered-service`;
    const body = mapServiceParam(service)
    request(path, { body, token });
  }, [serverPath, token]);

  const createOrder = useCallback(async (templateId: string, order: CreateOrderParam) => {
    const path = `${serverPath}/order/create/${templateId}`;
    const body = mapCreateOrderParam(order);

    setLoading(true);
    try {
      const orderId = await request<ApiCreateOrder, number>(path, { body, token });

      setLoading(false);

      return { orderId: `${orderId}` };
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }

      setLoading(false);

      return { orderId: null };
    }
  }, [serverPath, token]);

  const cancelOrder = useCallback(async (id: string) => {
    const path = `${serverPath}/order/cancel/`;
    const body = {
      'OrderId': id,
    };

    setLoading(true);
    try {
      request(path, { body, token });
      setOrderDetails(null);
      setOrders([]);
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token]);

  const deleteOrder = useCallback(async (id: string) => {
    const path = `${serverPath}/order/delete/`;
    const body = {
      'OrderId': id,
    };

    setLoading(true);
    try {
      request(path, { body, token });
      setOrderDetails(null);
      setOrders([]);
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token]);

  const downloadOrderFiles = useCallback(async (
    orderId: string,
    orderServiceId: string,
    resolutions: { name: string }[],
    filesIds: string[],
    isDraft: boolean
  ) => {
    const path = `${serverPath}/order/download-files/`;

    const resolutionsData: string[] = [];
    for (let i = 0; i < resolutions.length; i++) {
      resolutionsData.push(resolutions[i].name);
    }

    if (!filesIds) {
      filesIds = [];
    }

    const body = {
      OrderId: orderId,
      OrderServiceId: orderServiceId,
      Resolutions: resolutionsData,
      SelectedFiles: [],
      // Service: mapServiceParam(service)
    };

    request<typeof body, ApiFileDownloadRequestID>(path, { body, token }).then((res) => {
      const dlPath = `${serverPath}/order/${isDraft ? "download-draft-archive" : "download-archive"}/${res.FileDownloadRequestID}`;
      window.open(dlPath, '_self');
    });
  }, [serverPath, token]);

  const generateOrder = useCallback(async (templateId: string, quantity: number, applUserId: string) => {
    const body = {
      ApplicationUserId: applUserId,
      OrderTemplateId: templateId,
      Quantity: quantity,
    };
    const path = `${serverPath}/order/generate/`;
    request(path, { body, token });
  }, [serverPath, token]);

  const getNotes = useCallback(async (orderId: string) => {
    const path = `${serverPath}/order/${orderId}/notes`;

    const res = await request<never, ApiOrderNote[]>(path, { method: 'GET', token });

    return mapApiOrderNotes(res);
  }, [serverPath, token]);

  const getDetailedOrderData = useCallback(async (id: string) => {
    const path = `${serverPath}/order/get-details/${id}/`;

    setLoading(true);
    try {
      const orderDetailsResponse = await request<never, ApiOrderDetails>(path, { token });
      const notes = await getNotes(id);

      setOrderDetails(mapApiOrderDetails(orderDetailsResponse, notes));
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token]);

  const getDetailedOrderDataAnon = useCallback(async (id: string) => {
    const path = `${serverPath}/order/get-details-anon/${id}/`;

    setLoading(true);
    try {
      const orderDetailsResponse = await request<never, ApiOrderDetails>(path, { });
      const notes = await getNotes(id);

      setOrderDetails(mapApiOrderDetails(orderDetailsResponse, notes));
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath]);

  const findMaintainer = useCallback(async (filter: string) => {
    const path = `${serverPath}/order/find-maintainer/`;
    const res = await request<{ filter: string }, ApiMaintainer[]>(path, { body: { filter }, token });

    return mapApiMaintainers(res);
  }, [serverPath, token]);

  const findWorker = useCallback(async (filter: string) => {
    const path = `${serverPath}/order/find-worker/`;
    const res = await request<{ filter: string }, ApiWorker[]>(path, { body: { filter }, token });

    return workersApiMapper(res)
  }, [serverPath, token]);

  const getAppointments = useCallback(async (from: Date, to: Date) => {
    const path = `${serverPath}/order/get-appointments/`;
    const body = { End: to, Start: from };
    const res = request<{ End: Date, Start: Date }, Appointment[]>(path, { body, token });

    return res;
  }, [serverPath, token]);

  const getInvoiceList = useCallback(async (filters?: OrderFilterParams) => {
    const path = `${serverPath}/order/invoice-list/`;
    const body = mapOrderFilter(filters);
    request(path, { body, token });
  }, [serverPath, token]);

  // const getList = useCallback(async (branches) => {
  //   const body = branches;
  //   const path = `${serverPath}/order/list/`;
  //   request(path, { body, token });
  // }, [serverPath, token]);

  const getOrderImages = useCallback(async (orderId: string) => {
    const path = `${serverPath}/order/order-photos/${orderId}`;
    request(path, { method: 'GET', token });
  }, [serverPath, token]);

  const getOrderList = useCallback(async (filters?: OrderFilterParams) => {
    const path = `${serverPath}/order/filtered-list/`;
    const body = mapOrderFilter(filters);
    const res = await request<ApiOrderFilterParams, ApiOrder[]>(path, { body, token });

    setOrders(mapApiOrder(res));
  }, [serverPath, token]);

  const getOrderListOuttakes = useCallback(async (filters?: OrderFilterParams) => {
    const path = `${serverPath}/order/filtered-list-outtakes/`;
    const body = mapOrderFilter(filters);
    const res = await request<ApiOrderFilterParams, ApiOrder[]>(path, { body, token });

    setOrdersOuttakes(mapApiOrder(res));
  }, [serverPath, token]);

  const getOrderListForInvoice = useCallback(async (filters?: OrderFilterParams) => {
    const path = `${serverPath}/order/orders-for-invoice/`;
    const body = mapOrderFilter(filters);
    const res = await request<ApiOrderFilterParams, ApiOrder[]>(path, { body, token });

    setOrdersForInvoice(mapApiOrder(res));
  }, [serverPath, token]);

  const getPriceForService = useCallback(async (orderId: string, service: ServiceParam) => {
    const path = `${serverPath}/order/${orderId}/service/price`;
    const body = mapServiceParam(service)
    request(path, { body, token });
  }, [serverPath, token]);

  const getUnmaintainedList = useCallback(async () => {
    const path = `${serverPath}/order/unmaintained-list/`;
    const res = await request<never, number>(path, { method: 'POST', token });
    setUnmaintainedOrdersCount(res);
  }, [serverPath, token]);

  const getOrderOuttakesCount = useCallback(async () => {
    const path = `${serverPath}/order/outtakes-count/`;
    const res = await request<never, number>(path, { method: 'GET', token });
    setOrderOuttakesCount(res);
  }, [serverPath, token]);

  const markServiceAsComplete = useCallback(async (orderServiceId: string) => {
    const path = `${serverPath}/order/mark-as-complete/${orderServiceId}`;
    request(path, { token });
  }, [serverPath, token]);

  const notifyCustomer = useCallback(async (orderServiceId: string) => {
    const path = `${serverPath}/order/notify-customer/${orderServiceId}`;
    request(path, { token });
  }, [serverPath, token]);

  const notifyCustomerDraft = useCallback(async (orderServiceId: string) => {
    const path = `${serverPath}/order/notify-customer-draft/${orderServiceId}`;
    request(path, { token });
  }, [serverPath, token]);

  const orderAdditionalService = useCallback(async (orderId: string, service: ServiceParam) => {
    const path = `${serverPath}/order/${orderId}/service`;
    const body = mapServiceParam(service)
    request(path, { body, token });
  }, [serverPath, token]);

  const ordersReport = useCallback(async (from: string, to: string) => {
    const path = `${serverPath}/order/orders-report/`;
    const res = await request<never, { FileDownloadRequestID : string }>(path, {token});
    const filePath = `${path}${res.FileDownloadRequestID}/${from}/${to}`;
    window.open(filePath, '_blank');
  }, [serverPath, token]);

  const reAssignWorker = useCallback(async (orderServiceId: string, applicationUserId: string) => {
    const path = `${serverPath}/order/re-assign-worker/`;
    const body = {
      ApplicationUserId: applicationUserId,
      OrderServiceId: orderServiceId,
    };
    request(path, { body, token });
  }, [serverPath, token]);

  const setDetailedOrderData = useCallback(async (order: UpdateOrderParam) => {
    const path = `${serverPath}/order/set-details/`;

    const body = mapUpdateOrderParam(order);

    setLoading(true);
    try {
      const orderDetailsResponse = await request<ApiCreateOrder, ApiOrderDetails>(path, { body, token });
      const notes = await getNotes(`${orderDetailsResponse.OrderId}`);

      setOrderDetails(mapApiOrderDetails(orderDetailsResponse, notes));
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token]);

  const shareOrder = useCallback(async (orderId: string, email: string) => {
    const path = `${serverPath}/order/share-order/${orderId}`;
    const body = {
      Email: email
    };

    request(path, { body, token });
  }, [serverPath, token]);

  // const systemSuggest = useCallback(async (body) => {
  //   const path = `${serverPath}/order/suggest-worker/`;
  //   request(path, { body, token });
  // }, [serverPath, token]);

  return {
    addDraftFiles,
    addNote,
    cancelOrder,
    changeTemplate,
    confirmDraftFiles,
    confirmFiles,
    createOfferedService,
    createOrder,
    deleteOrder,
    downloadOrderFiles,
    findMaintainer,
    findWorker,
    generateOrder,
    getAppointments,
    getDetailedOrderData,
    getDetailedOrderDataAnon,
    getInvoiceList,
    // getList,
    getNotes,
    getOrderImages,
    getOrderList,
    getOrderListForInvoice,
    getOrderListOuttakes,
    getOrderOuttakesCount,
    getPriceForService,
    getUnmaintainedList,
    markServiceAsComplete,
    notifyCustomer,
    notifyCustomerDraft,
    orderAdditionalService,
    orderDetails,
    orderOuttakesCount,
    orders,
    ordersForInvoice,
    ordersOuttakes,
    ordersReport,
    reAssignWorker,
    setDetailedOrderData,
    shareOrder,
    unmaintainedOrdersCount,
    // systemSuggest,
  }
}

export default hook;