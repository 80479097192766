import { Loading, Notification } from "#/atoms";

import { RegisterAccountParams } from "@/api/account/interface";
import { Signup } from "#/pages";
import { useApi } from "@/api";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const SignupView = () => {
  const navigate = useNavigate();
  const { account, errorMessage, loading } = useApi()

  // useEffect(() => {
  //   if (session.user) {
  //     navigate('/', { replace: true })
  //   }
  // }, [session]);

  const handleLogin = useCallback(() => {
    navigate('/login', { replace: true })
  }, []);

  const handleSignupWorker = useCallback(() => {
    navigate('/register-worker', { replace: true })
  }, []);

  const handleSignup = useCallback((data: RegisterAccountParams) => {
    account.register(data);
  }, []);

  return (
    <>
      {loading && <Loading />}
      {errorMessage && <Notification>{errorMessage}</Notification>}
      <Signup onLogin={handleLogin} onSignup={handleSignup} onSignupWorker={handleSignupWorker}/>
    </>
  );
}

export default SignupView;