import { StyledImage, StyledPhotoplanLogo } from "./PhotoplanLogo.styled";

import { FC } from "react";
import { PhotoplanLogoProps } from "./PhotoplanLogo.interface";
// import { PhotoplanLogo as SVG } from "@/assets";

export const PhotoplanLogo: FC<PhotoplanLogoProps> = ({ isWhite, width }) => {
  return (
    <StyledPhotoplanLogo title="Photoplan">
      {/* <SVG color={color} /> */}
      <StyledImage src={isWhite ? "logo-text-white.png" : "logo-text-black.png"} alt="Photoplan" width={width} />
    </StyledPhotoplanLogo>
  );
}

export default PhotoplanLogo;
