import { Settings } from "@/components/pages";
import { useApi } from "@/api";
import { useEffect } from "react";

const SettingsView = () => {
  const { settings, upsell } = useApi();

  const getSettings = async () => {
    await settings.get();
  }

  const getUpsell = async () => {
    await upsell.get();
  }

  const getTemplates = async () => {
    await settings.listTemplates();
  }

  useEffect(() => {
    if (!upsell.upsell) {
      getUpsell();
    }

    getSettings();
    getTemplates();
  }, [settings, upsell]);
  
  return (
    <Settings
      onSaveSettings={settings.set}
      onSaveVirtualTourSettings={settings.setVirtualTour}
      onSaveCancelFeeSettings={settings.setCancelFee}
      onSaveInvoiceDueDaysSettings={settings.setInvoiceDueDays}
      onSaveUpsell={upsell.set}
      onAddSmsTemplate={settings.addTemplate}
      onEditSmsTemplate={settings.editTemplate}
      onDeleteSmsTemplate={settings.deleteTemplate}
      onReloadTemplates={getTemplates}
      settings={settings.settings}
      smsTemplates={settings.smsTemplates}
      upsell={upsell.upsell}
    />
  );
}

export default SettingsView;