import { Alert, Button, ButtonsGroup, GlobalStyles, Heading, InputSelect, InputText, Link, Typo } from "#/atoms";
import { DESKTOP_SPACING, HPOSITION, NATURAL, PRIMARY_BLUE } from "@/theme";
import { FC, memo, useCallback, useState } from "react";
import { StyledImage, StyledLoginForm, StyledLogo, StyledSignup, StyledSignupForm, StyledSignupImage } from "./SignupWorker.styled";
import { useInput, useInputSelect } from "@/hooks";

import { PhotoplanLogo } from "#/atoms";
import { RadioButtonGroup } from "@/components/molecules";
import { RadioButtonProps } from "@/components/molecules/RadioButtonGroup/RadioButtonGroup.interface";
import { SignupWorkerProps } from "./SignupWorker.interface";
import bgImage from "@/assets/bg.jpeg";

const SignupWorker: FC<SignupWorkerProps> = ({ onLogin, onSignup, onSignupWorker }) => {
  const [error, setError] = useState<string | null>(null);
  const firstname = useInput();
  const lastname = useInput();
  const login = useInput();
  const password = useInput();
  const confirmPassword = useInput();
  const address = useInput();
  const town = useInput();
  const phone = useInput();
  const postcode = useInput();
  const commutingDistance = useInput();
  const commutingDistanceType = useInput('0');
  const websiteLink = useInput();
  const agree = useInputSelect('', false);

  const getIsValid = () => {
    return !!firstname.value && !!lastname.value && !!login.value && !!password.value && !!confirmPassword.value && !!address.value && !!town.value && !!phone.value 
      && !!postcode.value && !!commutingDistance.value && !!websiteLink.value;
  }

  const cancelFeeTypes : RadioButtonProps[] = [{
        id: "1",
        label: "Kilonetres",
        value: "0"
      },
      {
        id: "2",
        label: "Miles",
        value: "1"
      }
    ]

    const handleCancelFeeTypeChange = useCallback((groupName: string, selectedValue: string) => {
      commutingDistanceType.setValue(selectedValue);
    }, []);

  const handleRegister = useCallback(() => {
    setError(null);

    if (!agree.checked || !getIsValid()) {
      return;
    }
    
    onSignupWorker({
      addressString: address.value,
      commutingDistance: parseInt(commutingDistance.value, 10),
      commutingDistanceType: parseInt(commutingDistanceType.value, 10),
      email: login.value,
      firstName: firstname.value,
      lastName: lastname.value,
      password: password.value,
      passwordConfirm: confirmPassword.value,
      phoneNumber: phone.value,
      postcode: postcode.value,
      town: town.value,
      websiteLink: websiteLink.value
    });
  }, [firstname, lastname, login, password, confirmPassword, address, town, phone, postcode, commutingDistance, websiteLink]);
  
  return (
    <>
      <GlobalStyles backgroundColor={NATURAL.A} />
      {error && <Alert type="error">{error}</Alert>}
      <StyledSignup>
        <StyledLogo>
          <PhotoplanLogo color={NATURAL.K} />
        </StyledLogo>
        <StyledSignupForm>
          <Heading scale="h2" fontColor={NATURAL.K}>New Worker</Heading>
          <InputText type="text" label="First name" value={firstname.value} onChange={firstname.onChange} />
          <InputText type="text" label="Last name" value={lastname.value} onChange={lastname.onChange} />
          <InputText type="email" label="Email" value={login.value} onChange={login.onChange} />
          <InputText type="password" label="Password" value={password.value} onChange={password.onChange} />
          <InputText type="password" label="Confirm Password" value={confirmPassword.value} onChange={confirmPassword.onChange} />
          <InputText type="tel" label="Phone" value={phone.value} onChange={phone.onChange} />
          <InputText type="text" label="First Line of Address" value={address.value} onChange={address.onChange} />
          <InputText type="text" label="Town" value={town.value} onChange={town.onChange} />
          <InputText type="text" label="Postcode" value={postcode.value} onChange={postcode.onChange} />
          <InputText type="number" label="Commuting Distance" value={commutingDistance.value} onChange={commutingDistance.onChange} />
          <RadioButtonGroup groupName="cancelFeeType" inputs={cancelFeeTypes} selectedValue={commutingDistanceType.value} onChangeValue={handleCancelFeeTypeChange} />
          <InputText type="text" label="Website Link" value={websiteLink.value} onChange={websiteLink.onChange} />
          
          <InputSelect
            type="checkbox"
            checked={agree.checked}
            label={(
              <Typo>
                Agree to <Link href="https://www.photoplan.co.uk/terms/" fontColor={PRIMARY_BLUE.DARK} target="_blank">Terms and Conditions</Link>
              </Typo>
            )}
            margin={`${DESKTOP_SPACING.XXL}px 0 0`}
            onChangeValue={agree.onChange}
            value={agree.value}
          />
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
            <Button state={getIsValid()  ? 'default' : 'disabled'}  onClick={handleRegister}>Register</Button>
          </ButtonsGroup>
        </StyledSignupForm>
      </StyledSignup>
      <StyledImage>
        <StyledLoginForm>
          <Heading scale="h1" fontColor={NATURAL.A}>Already have an account?</Heading>
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth margin="0 0 40px">
            <Button onClick={onLogin}>Login</Button>
          </ButtonsGroup>
          <Heading scale="h1" fontColor={NATURAL.A}>Register as customer instead?</Heading>
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth>
            <Button onClick={onSignup}>Register as customer</Button>
          </ButtonsGroup>
        </StyledLoginForm>
        <StyledSignupImage src={bgImage} />
      </StyledImage>
    </>
  );
}

export default memo(SignupWorker);
