import { ApiSettings, ApiSmsTemplate } from "./interface.api";
import { Settings, SmsTemplate } from "@/types";
import { useCallback, useState } from "react";

import { ApiHooksProps } from "../interface";
import { SettingsApi } from "./interface";
import { request } from "../request";
import { smsTemplatesMapper} from "./mappers";

const hook = ({ setError, setLoading, setSuccess, serverPath, token }: ApiHooksProps): SettingsApi => {
  const [settings, setSettings] = useState<Settings | null>(null)
  const [smsTemplates, setSmsTemplates] = useState<SmsTemplate[]>([])
  
  const addTemplate = useCallback(async (data : SmsTemplate) => {
    const path =  `${serverPath}/settings/addTemplate/`;
    const body = {
      MessageText: data.text,
      Title: data.subject
    }

    await request(path, { body, method: 'POST', token });
  }, [serverPath, token]);

  const deleteTemplate = useCallback(async (id: string) => {
    const path = `${serverPath}/settings/deleteTemplate/${id}`;
    await request(path, { method: 'DELETE', token });
  }, [serverPath, token]);

  const editTemplate = useCallback(async (data: SmsTemplate) => {
    const path = `${serverPath}/settings/editTemplate/${data.id}`;
    const body = {
      MessageText: data.text,
      Title: data.subject
    }

    await request(path, { body, method: 'POST', token });
  }, [serverPath, token]);

  const get = useCallback(async () => {
    const path = `${serverPath}/settings/get/`;
    const res = await request<never, ApiSettings>(path, { method: 'GET', token });
    setSettings({
      cancelFeeAmount: res.CancelFeeAmount,
      cancelFeeType: res.CancelFeeType,
      floorAreaSurchargeAmount: res.FloorAreaSurchargeAmount,
      floorAreaSurchargeThreshold: res.FloorAreaSurchargeThreshold,
      id: res.SettingId,
      invoiceDueDateDays: res.InvoiceDueDateDays,
      virtualTourImagesSurchargeAmount: res.VirtualTourImagesSurchargeAmount,
      virtualTourImagesThreshold: res.VirtualTourImagesThreshold
    })
  }, [serverPath, token]);

  const listTemplates = useCallback(async () => {
    const path = `${serverPath}/settings/listTemplates/`;
    const res = await request<never, ApiSmsTemplate[]>(path, { method: 'GET', token });

    setSmsTemplates(smsTemplatesMapper(res))
  }, [serverPath, token]);

  const set = useCallback(async (data: Settings) => {
    const path = `${serverPath}/settings/set/`;
    const body: ApiSettings = {
      FloorAreaSurchargeAmount: data.floorAreaSurchargeAmount,
      FloorAreaSurchargeThreshold: data.floorAreaSurchargeThreshold,
      SettingId: data.id,
    }
    
    setLoading(true);
    try {
      await request<ApiSettings, Settings>(path, { body, token });
      setSettings(data);
      setSuccess('Settings updated!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token, settings]);

  const setVirtualTour = useCallback(async (data: Settings) => {
    const path = `${serverPath}/settings/set-virtual-tour/`;
    const body: ApiSettings = {
      SettingId: data.id,
      VirtualTourImagesSurchargeAmount: data.virtualTourImagesSurchargeAmount,
      VirtualTourImagesThreshold: data.virtualTourImagesThreshold,
    }
    
    setLoading(true);
    try {
      await request<ApiSettings, Settings>(path, { body, token });
      setSettings(data);
      setSuccess('Settings updated!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token, settings]);

  const setCancelFee = useCallback(async (data: Settings) => {
    const path = `${serverPath}/settings/set-cancel-fee/`;
    const body: ApiSettings = {
      CancelFeeAmount: data.cancelFeeAmount,
      CancelFeeType: data.cancelFeeType,
      SettingId: data.id,
    }
    
    setLoading(true);
    try {
      await request<ApiSettings, Settings>(path, { body, token });
      setSettings(data);
      setSuccess('Settings updated!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token, settings]);

  const setInvoiceDueDays = useCallback(async (data: Settings) => {
    const path = `${serverPath}/settings/set-invoice-due/`;
    const body: ApiSettings = {
      InvoiceDueDateDays: data.invoiceDueDateDays,
      SettingId: data.id,
    }
    
    setLoading(true);
    try {
      await request<ApiSettings, Settings>(path, { body, token });
      setSettings(data);
      setSuccess('Settings updated!');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  }, [serverPath, token, settings]);

  return {
    addTemplate,
    deleteTemplate,
    editTemplate,
    get,
    listTemplates,
    set,
    setCancelFee,
    setInvoiceDueDays,
    setVirtualTour,
    settings,
    smsTemplates
  }
}

export default hook;