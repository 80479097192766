import { Button, ButtonsGroup, Card, Grid, Heading, InputText, PageHeader, PageWrapper, Typo } from "#/atoms";
import { DESKTOP_SPACING, FONT_SIZE, NATURAL, PRIMARY_BLUE } from "@/theme";
import { FC, memo, } from "react";
import { OrdersReportProps } from "./OrdersReport.interface";
import { formatShortDateString } from "@/helpers";
import { useInput } from "@/hooks";

const OrdersReport: FC<OrdersReportProps> = ({ onDownload }) => {

  const dateFrom = useInput('');
  const dateTo = useInput('');

  const handleClear = () => {
    dateFrom.setValue('');
    dateTo.setValue('');
  }

  const handleDownload = () => {
    const dateFromString = formatShortDateString(dateFrom.value);
    const dateToString = formatShortDateString(dateTo.value);
    onDownload(dateFromString || formatShortDateString(new Date().toDateString()), dateToString || "31122030");
  }

  return (
    <PageWrapper>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>Download Order Reports</Heading>
        <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Download an orders report between two given dates. Both dates can be empty, &apos;from&apos; will default to today&apos;s date and &apos;to&apos; to a distant future date</Typo>
      </PageHeader>

      <Card>
        <Heading display="flex" scale="h4" fontColor={PRIMARY_BLUE.DARK}>
          Filter criteria
        </Heading>
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 1fr)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
          <InputText type="date" placeholder=" " label="Appointment Date From" value={dateFrom.value} onChange={dateFrom.onChange} />
          <InputText type="date" placeholder=" " label="Appointment Date To" value={dateTo.value} onChange={dateTo.onChange} />
        </Grid>
        <ButtonsGroup fullWidth>
          <Button actionType="outline" onClick={handleClear}>Clear Criteria</Button>
          <Button onClick={handleDownload}>Download</Button>
        </ButtonsGroup>
      </Card>
    </PageWrapper>
  );
};

export default memo(OrdersReport);