import { Accordion, Card, Grid, InputText } from "#/atoms";
import { FC, memo } from "react";

import { DESKTOP_SPACING } from "@/theme";
import { HeadingIcon } from "#/molecules/HeadingIcon";
import { OrderExtraChargeCardProps } from "./OrderExtraCharge.interface";

const OrderExtraChargeCard: FC<OrderExtraChargeCardProps> = ({
  accordionState = 'open',
  orderExtraChargeDescription,
  orderExtraChargePriceExVat
}) => {
  return (
    <Card>
      <Accordion
        header={<HeadingIcon icon="wallet" title="Extra Charge" />}
        showArrow
        state={accordionState}
      >
        <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 40%)" margin="0">
        <InputText
            type="number"
            label="Amount to Charge"
            value={orderExtraChargePriceExVat.value}
            onChange={orderExtraChargePriceExVat.onChange}
          />
        <InputText
            type="text"
            label="Charge Description"
            value={orderExtraChargeDescription.value}
            onChange={orderExtraChargeDescription.onChange}
          />
        </Grid>
      </Accordion>
    </Card>
  );
};

export default memo(OrderExtraChargeCard);