import { StyledPhotoplanLogoProps } from "./PhotoplanLogo.interface";
import styled from "styled-components";

export const StyledPhotoplanLogo = styled.a`
  cursor: pointer;
  width: 129px;
`;

export const StyledImage = styled.img<StyledPhotoplanLogoProps>`
  width: ${({ width }) => width || "350px"};
`;