import {
  ApiBaseWorkerService,
  ApiDeleteFiles,
  ApiGoogleCalendarItem,
  ApiOrderListFilteredItem,
  ApiOrderNote, ApiWorker,
  ApiWorkerCommonFilters,
  ApiWorkerData,
  ApiWorkerDetails,
  ApiWorkerFilters,
  ApiWorkerInvoice,
  ApiWorkerPostCodeResponse,
  ApiWorkerSendMessage,
  ApiWorkerService } from "./interface.api";
import { NewWorkerParams, WorkerApi } from "./interface";
import { WorkerCommonFilters, WorkerFilters } from "./interface";
import { getGooglePath, request, sendFiles } from "../request";
import { mapApiOrderNotes, workerApiMapper, workerDetailsApiMapper, workerInvoicesApiMapper, workerServicesApiMapper, workersApiMapper } from './mappers';
import { useCallback, useState } from "react";

import { ApiHooksProps } from "../interface";
import { ApiOrderFile } from "../order/interface.api";
import { Worker } from "@/types";
import { mapApiOrderFiles } from "../order/mappers";

const hook = ({ serverPath, token }: ApiHooksProps): WorkerApi => {
  const [workers, setWorkers] = useState<Worker[]>([]);
  
  const acceptOffer =  useCallback(async (id: string) => {
    const path = `${serverPath}/workers/confirm-order/${id}`;
    request(path, { token });
  }, [serverPath, token]);
  
  const addNote = useCallback(async (workerId: number, note: string) => {
    const path = `${serverPath}/notes/create`;
    const body = {
      Content: note,
      WorkerId: workerId
    }

    request(path, { body, token });
  }, [serverPath, token]);

  const addPostcode = useCallback(async (workerId: string, postcode: string) => {
    const body = {
      Postcode: postcode,
      WorkerId: workerId,
    };
    const path = `${serverPath}/workers/add-postcode/`;
    const res = await request<any, ApiWorkerPostCodeResponse>(path, { body, token });
    
    return res;
  }, [serverPath, token]);

  const combinePhotos = useCallback(async (orderServiceId: string, fileIds: string[], isOffered: boolean) => {
    const path = `${serverPath}/workers/combine-files/${orderServiceId}`;
    const body = {
      IsOffered: isOffered,
      files: fileIds.map(f => ({ UploadFileId: f }))
    };

    request(path, { body, token });
  }, [serverPath, token]);

  const create = useCallback(async (worker: NewWorkerParams) => {
    const path = `${serverPath}/workers/new`;
    const body = {
      ...worker
    }
    const res = await request<NewWorkerParams, ApiWorker>(path, { body, token });
    const newWorker = workerApiMapper(res);
    setWorkers([...workers, newWorker]);
  }, [serverPath, token, workers]);
  
  const deletePhotos = useCallback(async (uploadFileIds: string[], orderServiceId: string, isOffered: boolean)=> {
    const path = `${serverPath}/workers/delete-files/${orderServiceId}`;

    const body: ApiDeleteFiles = {
      IsOffered : isOffered,
      files: uploadFileIds.map((uploadFileId) => ({
        UploadFileId: uploadFileId,
      }))
    };

    return await request<ApiDeleteFiles, void>(path, { body, token });
  }, [serverPath, token]);
  
  const deletePortfolioImage = useCallback( async (uploadFileId: string)=> {
    const path = `${serverPath}/workers/delete-portfolio-item/${uploadFileId}`;
    request(path, { token });
  }, [serverPath, token]);

  const deletePostcode = useCallback( async (postcodeId: string) => {
    const path = `${serverPath}/workers/delete-postcode/`;

    const body = {
      WorkerPostCodeId: postcodeId
    }

    request(path, { body, token });
  }, [serverPath, token]);

  const deleteWorker = useCallback( async (workerId: string) => {
    const path = `${serverPath}/workers/delete/${workerId}`;

    request(path, { method: 'DELETE', token });
  }, [serverPath, token]);

  const getCalendarId = useCallback( async (workerId: string) => {
    const path = `${serverPath}/workers/get-calendar/${workerId}`;
    
    return await request<never, string>(path, { token });
  }, [serverPath, token]);
  
  const getCalendarList = useCallback( async () => {
    const path = `${serverPath}/workers/get-google-calendars`;
    
    return await request<never, ApiGoogleCalendarItem[]>(path, { method: 'GET', token });
  }, [serverPath, token]);

  const getData = async (workerId: string) => {
    const path = `${serverPath}/workers/get-data/${workerId}/`;
    const res = await request<never, ApiWorkerData>(path, { token });

    return workerDetailsApiMapper(res);
  };

  const getFilteredList = useCallback(async (filters?: WorkerFilters) => {
    const body: ApiWorkerFilters = {
      DisplayName: filters?.displayName || '',
      EmailAddress: filters?.email || '',
      Login: filters?.login || '',
    };
    const path = `${serverPath}/workers/filtered-list`;
    const res = await request<ApiWorkerFilters, ApiWorker[]>(path, { body, token});
    setWorkers(workersApiMapper(res))
  }, [serverPath, token]);
  
  const getFloorArea = useCallback(async (orderServiceId: string)=> {
    const path = `${serverPath}/workers/get-floor-area/${orderServiceId}`;
    const res = await request<never, { FloorArea: number }>(path, { method: 'GET', token});

    return res?.FloorArea ?? 0;
  }, [serverPath, token]);
  
  const getVirtualTour = useCallback(async (orderServiceId: string)=> {
    const path = `${serverPath}/workers/get-virtual-tour/${orderServiceId}`;
    const res = await request<never, { VirtualTourPhotos: number }>(path, { method: 'GET', token});

    return res?.VirtualTourPhotos ?? 0;
  }, [serverPath, token]);

  const getList = useCallback(async (filters?: WorkerCommonFilters) => {
    const body: ApiWorkerCommonFilters = {
      EmailAddress: filters?.email || '',
      IsActive: typeof(filters?.active) == "undefined" ? null : filters?.active,
      Name : filters?.name || '',
      PostCode: filters?.postCode || '',
      WorkerTypeId: filters?.workerTypeId || null,
    };
    const path = `${serverPath}/workers/list`;
    const res = await request<ApiWorkerCommonFilters, ApiWorker[]>(path, { body, token});
    setWorkers(workersApiMapper(res))
  }, [serverPath, token]);
  
  const getNotes = useCallback(async (workerId: string) => {
    const path = `${serverPath}/workers/${workerId}/notes`;

    const res = await request<never, ApiOrderNote[]>(path, { method: 'GET', token });

    return mapApiOrderNotes(res);
  }, [serverPath, token]);
  
  const getPortfolio = useCallback(async (id: string)=> {
    const path = `${serverPath}/workers/get-portfolio/${id}`;
    request(path, { token });

    const res = await request<never, ApiOrderFile[]>(path, { token });

    return mapApiOrderFiles(res);
  }, [serverPath, token]);

  const getServices = async (workerId: string) => {
    const path = `${serverPath}/workers/get-services/${workerId}`;
    const res = await request<never, ApiWorkerService[]>(path, { token });

    return workerServicesApiMapper(res);
  };

  const getWorkerOfferList =  useCallback(async () => {
    const path = `${serverPath}/workers/get-confirm-notifications/`;
    const res = await request<never, ApiOrderListFilteredItem[]>(path, { token });

    return res;
  }, [serverPath, token]);

  const getWorkerInvoiceList = useCallback(async(filterType: string) => {
    const path = `${serverPath}/workers/list-invoices/${filterType}`;
    const res = await request<never, ApiWorkerInvoice[]>(path, { token });

    return workerInvoicesApiMapper(res);
  }, [serverPath, token]);

  const inviteWorker = useCallback(async (workerId: string) => {
    const path = `${serverPath}/workers/invite/${workerId}`;
    request(path, { token });
  }, [serverPath, token]);

  const movePhotos = useCallback(async (orderServiceId: string, fileIds: string[], isOffered: boolean) => {
    const path = `${serverPath}/workers/move-files/${orderServiceId}`;
    const body = {
      IsOffered: isOffered,
      files: fileIds.map(f => ({ UploadFileId: f }))
    };

    request(path, { body, token });
  }, [serverPath, token]);
  
  const recordFloorArea = useCallback(async (orderServiceId: string, floorArea: number) => {
    const path = `${serverPath}/workers/record-floor-area/${orderServiceId}`;
    const body = {
      FloorArea: floorArea
    }

    request(path, { body, token });
  }, [serverPath, token]);
  
  const recordVirtualTour = useCallback(async (orderServiceId: string, virtualTour: number) => {
    const path = `${serverPath}/workers/record-virtual-tour/${orderServiceId}`;
    const body = {
      VirtualTourPhotos: virtualTour
    }

    request(path, { body, token });
  }, [serverPath, token]);
  
  const redirectSyncGoogleAccount = useCallback(async () => {
    const path = `${getGooglePath()}?redirect=${encodeURIComponent(window.location.href)}`;

    request<never, string>(path, { method: 'GET', token }).then((res) => {
        window.location.href = res;
    });
  }, [serverPath, token]);

  const rejectOffer = useCallback(async (id: string, reason: string) => {
    const path = `${serverPath}/workers/reject-order/${id}`;
    const body = { Reason: reason };
    request(path, { body, token });
  }, [serverPath, token]);

  const sendOrderMessage = useCallback(async (body: ApiWorkerSendMessage) => {
    const path = `${serverPath}/workers/order-message/`;
    request(path, { body, token });
  }, [serverPath, token]);
  
  const unlinkGoogleCalendar = useCallback(async () => {
    const path = `${serverPath}/workers/unbind-google-calendar`;
    
    return await request<never, boolean>(path, { token });
  }, [serverPath, token]);
  

  const updateDetails = useCallback(async (userId: string, data: ApiWorkerDetails ) => {
    const path = `${serverPath}/workers/update-details/${userId}`;
    const body = data;

    request(path, { body, token });
  }, [serverPath, token]);

  const updateServices = useCallback(async (userId: string, data: ApiBaseWorkerService[]) => {
    const path = `${serverPath}/workers/update-services/${userId}`;
    const body = {
      Services: data
    };

    request(path, { body, token });
  }, [serverPath, token]);

  const uploadEditedPhoto = useCallback((files: Blob[], uploadFileId: string) => {
    const path = `${serverPath}/workers/upload-edited-file/${uploadFileId}`;
    
    return sendFiles<void>(path, files, token);
  }, [serverPath, token]);

  const uploadEditedDraftPhoto = useCallback((files: Blob[], uploadFileId: string) => {
    const path = `${serverPath}/workers/upload-edited-draft-file/${uploadFileId}`;
    
    return sendFiles<void>(path, files, token);
  }, [serverPath, token]);

  const uploadFileOffered = useCallback((files: Blob[], offeredServiceId: string) => {
    const path = `${serverPath}/workers/upload-file-offered/${offeredServiceId}`;
    
    return sendFiles<void>(path, files, token);
  }, [serverPath, token]);

  const uploadPhotos = useCallback((files: Blob[], orderServiceId: string) => {
    const path = `${serverPath}/workers/upload-file/${orderServiceId}`;
    
    return sendFiles<void>(path, files, token);
  }, [serverPath, token]);

  const uploadDraftPhotos = useCallback((files: Blob[], orderServiceId: string) => {
    const path = `${serverPath}/workers/upload-draft-file/${orderServiceId}`;
    
    return sendFiles<void>(path, files, token);
  }, [serverPath, token]);

  const uploadPlans = useCallback((files: Blob[], orderServiceId: string) => {
    const path = `${serverPath}/workers/upload-plan/${orderServiceId}`;
    
    return sendFiles<void>(path, files, token);
  }, [serverPath, token]);

  const uploadDraftPlans = useCallback((files: Blob[], orderServiceId: string) => {
    const path = `${serverPath}/workers/upload-draft-plan/${orderServiceId}`;
    
    return sendFiles<void>(path, files, token);
  }, [serverPath, token]);


  const uploadPortfolio = useCallback(async (files: Blob[]) => {
    const path = `${serverPath}/workers/upload-portfolio/`;

    const res = await sendFiles<ApiOrderFile[]>(path, files, token);

    return mapApiOrderFiles(res);
  }, [serverPath, token]);

  return {
    acceptOffer,
    addNote,
    addPostcode,
    combinePhotos,
    create,
    deletePhotos,
    deletePortfolioImage,
    deletePostcode,
    deleteWorker,
    getCalendarId,
    getCalendarList,
    getData,
    getFilteredList,
    getFloorArea,
    getList,
    getNotes,
    getPortfolio,
    getServices,
    getVirtualTour,
    getWorkerInvoiceList,
    getWorkerOfferList,
    inviteWorker,
    movePhotos,
    recordFloorArea,
    recordVirtualTour,
    redirectSyncGoogleAccount,
    rejectOffer,
    sendOrderMessage,
    unlinkGoogleCalendar,
    updateDetails,
    updateServices,
    uploadDraftPhotos,
    uploadDraftPlans,
    uploadEditedDraftPhoto,
    uploadEditedPhoto,
    uploadFileOffered,
    uploadPhotos,
    uploadPlans,
    uploadPortfolio,
    workers,
  }
};

export default hook;
