import { Button, ButtonsGroup, InputText } from "#/atoms";
import { FC, memo } from "react";

import { DESKTOP_SPACING } from "@/theme";
import { ForgotPasswordProps } from "./ForgotPassword.interface";
import { Modal } from "#/molecules";


const ForgotPassword: FC<ForgotPasswordProps> = ({ onClose, onPasswordReset }) => {
  let email = '';
  
    return (
    <Modal onClose={onClose} title="Forgot Password" width="600px">
      <InputText type="email" label="Email" value={email} onChange={(v) => email = v.currentTarget.value } />
      <ButtonsGroup fullWidth margin={`${DESKTOP_SPACING.EL}px 0 0`}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onPasswordReset(email)}>Reset</Button>
      </ButtonsGroup>
    </Modal>
  );
}

export default memo(ForgotPassword);