export enum PRIMARY_BLUE {
  DARK ='#0AAEC2',
  DARKER ='#066874',
  DEFAULT = '#50E4FF',
  HOVER = '#0998AA',
  LIGHT = '#D2FAFF',
};
export const primaryBlue: PRIMARY_BLUE[] = [
  PRIMARY_BLUE.DARK,
  PRIMARY_BLUE.DARKER,
  PRIMARY_BLUE.DEFAULT,
  PRIMARY_BLUE.LIGHT
];

export enum SECONDARY_NAVY {
  DARK ='#080D37',
  DEFAULT ='#2A414A',
  LIGHT ='#555A83',
  LIGHTER ='#CED0DC',
};
export const secondaryNavy: SECONDARY_NAVY[] = [
  SECONDARY_NAVY.DARK,
  SECONDARY_NAVY.DEFAULT,
  SECONDARY_NAVY.LIGHT,
  SECONDARY_NAVY.LIGHTER
];

export enum SECONDARY_RED {
  DARK ='#AA001A',
  DEFAULT ='#D50020',
  LIGHT ='#E66679',
  LIGHTER ='#FFDBE0',
};
export const secondaryRed: SECONDARY_RED[] = [
  SECONDARY_RED.DARK,
  SECONDARY_RED.DEFAULT,
  SECONDARY_RED.LIGHT,
  SECONDARY_RED.LIGHTER
];

export enum TERTIARY_ORANGE {
  DARK ='#CC6A42',
  DEFAULT ='#FF8552',
  LIGHT ='#FFB697',
  LIGHTER ='#FFE7DC',
};
export const tertiaryOrange: TERTIARY_ORANGE[] = [
  TERTIARY_ORANGE.DARK,
  TERTIARY_ORANGE.DEFAULT,
  TERTIARY_ORANGE.LIGHT,
  TERTIARY_ORANGE.LIGHTER
];

export enum TERTIARY_PURPLE {
  DARK ='#5A6ECC',
  DEFAULT ='#7189FF',
  LIGHT ='#9CACFF',
  LIGHTER ='#E3E7FF',
};
export const tertiaryPurple: TERTIARY_PURPLE[] = [
  TERTIARY_PURPLE.DARK,
  TERTIARY_PURPLE.DEFAULT,
  TERTIARY_PURPLE.LIGHT,
  TERTIARY_PURPLE.LIGHTER
];

export enum TERTIARY_GREEN {
  DARK ='#929E3A',
  DEFAULT ='#B6C649',
  LIGHT ='#CCD780',
  LIGHTER ='#F0F4DB',
};
export const tertiaryGreen: TERTIARY_GREEN[] = [
  TERTIARY_GREEN.DARK,
  TERTIARY_GREEN.DEFAULT,
  TERTIARY_GREEN.LIGHT,
  TERTIARY_GREEN.LIGHTER
];

export enum NATURAL {
  A = '#FFFFFF', // 0
  B = '#F7F8FC', // 10
  C = '#EDEDF0', // 20
  D = '#CECED6', // 30
  E = '#B7B7C2', // 40
  F = '#9E9DAD', // 50
  G = '#818095', // 60
  H = '#5D5C76', // 70
  I = '#4B4A67', // 80
  J = '#353448', // 90
  K = '#1E1E29', // 100
};
export const natural: NATURAL[] = [
  NATURAL.A,
  NATURAL.B,
  NATURAL.C,
  NATURAL.D,
  NATURAL.E,
  NATURAL.F,
  NATURAL.G,
  NATURAL.H,
  NATURAL.I,
  NATURAL.J,
  NATURAL.K,
];

export const ICON_COLOR = '#818095';
export const MENU_ICON_COLOR = '#CECED6';

export type COLOR =
  | NATURAL
  | TERTIARY_GREEN
  | TERTIARY_PURPLE
  | TERTIARY_ORANGE
  | SECONDARY_RED
  | SECONDARY_NAVY
  | PRIMARY_BLUE;

export const colors: COLOR[] = [
  ...natural,
  ...tertiaryGreen,
  ...tertiaryPurple,
  ...tertiaryOrange,
  ...secondaryRed,
  ...secondaryNavy,
  ...primaryBlue,
]