import {
  Accordion,
  Box,
  Button,
  Card,
  Footer,
  Grid,
  Heading,
  InputSelect,
  InputText,
  Maps,
  PageHeader,
  PageWrapper,
  Select,
  Typo
} from "#/atoms";
import { DESKTOP_SPACING, FONT_SIZE, NATURAL } from "@/theme";
import { FC, ReactNode, memo, useCallback } from "react";
import { useInput, useInputSelect, useMaps, useSelect } from "@/hooks";

import { CustomerProps } from "./Customer.interface";
import { HeadingIcon } from "#/molecules";
import { countryList } from "@/helpers";

const Customer: FC<CustomerProps> = ({ onSave }) => {
  const { address, mapsAddress, onBlur, onChange } = useMaps();

  const email = useInput();
  const firstName = useInput();
  const lastName = useInput();
  const password = useInput();
  const passwordConfirm = useInput();
  const notification = useInputSelect('notification');
  const terms = useInputSelect('terms');

  const vat = useInputSelect('vat');

  const code = useInput();
  const companyName = useInput();
  const houseName = useInput();
  const houseNumber = useInput();
  const road = useInput();
  const area = useInput();
  const town = useInput();
  const county = useInput();
  const postcode = useInput();
  const countryId = useSelect('0');
  const active = useInputSelect('active');
  const payByCard = useInputSelect('payByCard');
  const useLowQualityThumbnails = useInputSelect('useLowQualityThumbnails');
  const scrubData = useInputSelect('scrubData');

  const contactName = useInput();
  const contactEmail = useInput();
  const contactPhone1 = useInput();
  const contactPhone2 = useInput();

  const handleSave = useCallback(async () => {
    await onSave(
      {
        active: false,
        branchId: '',
        code: code.value,
        companyName: companyName.value,
        confirmPassword: passwordConfirm.value,
        email: email.value,
        firstName: firstName.value,
        invited: false,
        lastName: lastName.value,
        password: password.value,
        vat: vat.checked,
      },
      {
        area: area.value,
        countryId: parseInt(countryId.value, 10),
        county: county.value,
        houseName: houseName.value,
        houseNumber: houseNumber.value,
        isCardPayment: payByCard.checked,
        isLowQuality: useLowQualityThumbnails.checked,
        postcode: address,
        road: road.value,
        shouldRemoveData: scrubData.checked,
        town: town.value,
      },
      {
        email: contactEmail.value,
        name: contactName.value,
        phone1: contactPhone1.value,
        phone2: contactPhone2.value,
        type: 0,
      },
      notification.checked
    );
  }, [
    active,
    address,
    area,
    code,
    companyName,
    contactEmail,
    contactName,
    contactPhone1,
    contactPhone2,
    county,
    email,
    firstName,
    houseName,
    houseNumber,
    lastName,
    notification,
    password,
    passwordConfirm,
    road,
    terms,
    town,
  ]);

  const labelLink : ReactNode = <>Agree to&nbsp;<a href="https://www.photoplan.co.uk/terms/" target="_blank" rel="noreferrer">Terms & Conditions</a></>;
  
  return (
    <>
      <PageWrapper pb="100px">
        <PageHeader>
          <Heading scale="h1" fontColor={NATURAL.K}>Create New Customer</Heading>
          <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Please provide us information to create new customer.</Typo>
        </PageHeader>

        <Card padding="0">
          <Box padding={`${DESKTOP_SPACING.EL}px`}>
            <Accordion
              header={<HeadingIcon icon="contacts" title="Account Details" />}
              onToggle={() => null}
              showArrow
              state="open"
            >
              <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(3, 30%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
                <InputText type="text" label="First Name" value={firstName.value} onChange={firstName.onChange} />
                <InputText type="text" label="Last Name" value={lastName.value} onChange={lastName.onChange} />
                <InputText type="text" label="E-mail Address" value={email.value} onChange={email.onChange} />
                <InputSelect type="checkbox" checked={vat.checked} label="Customer VAT" value={vat.value} onChangeValue={vat.onChange} />
                <InputText type="password" label="Password" value={password.value} onChange={password.onChange} />
                <InputText type="password" label="Confirm Password" value={passwordConfirm.value} onChange={passwordConfirm.onChange} />
              </Grid>
              <Grid rowGap={DESKTOP_SPACING.XL} gridTemplateRows="auto" margin={`${DESKTOP_SPACING.SM}px 0 0`}>
                <InputSelect
                  type="checkbox"
                  checked={notification.checked}
                  label="Send E-mail Notification"
                  value={notification.value}
                  onChangeValue={notification.onChange}
                />
                <InputSelect
                  type="checkbox"
                  checked={terms.checked}
                  label={labelLink}
                  value={terms.value}
                  onChangeValue={terms.onChange}
                />
              </Grid>
            </Accordion>
          </Box>
        </Card>

        <Card padding="0">
          <Grid gridTemplateColumns="repeat(2, 1fr)">
            <Box padding={`${DESKTOP_SPACING.EL}px`}>
              <HeadingIcon
                icon="location"
                title="Address Details"
              />
              <InputText type="text" label="Company Name" value={companyName.value} onChange={companyName.onChange} />
              <InputText type="text" label="Customer Code" value={code.value} onChange={code.onChange} />
              <InputText type="text" label="House Name" value={houseName.value} onChange={houseName.onChange} />
              <InputText type="text" label="Number" value={houseNumber.value} onChange={houseNumber.onChange} />
              <InputText type="text" label="Road" value={road.value} onChange={road.onChange} />
              <InputText type="text" label="Area" value={area.value} onChange={area.onChange} />
              <InputText type="text" label="Town" value={town.value} onChange={town.onChange} />
              <InputText type="text" label="County" value={county.value} onChange={county.onChange} />
              <InputText type="text" label="Postcode" value={postcode.value} onBlur={onBlur} onChange={postcode.onChange} />
              <Select label="Country"
                  options={Object.keys(countryList).map((k) => { return { id: k.replace("C", ""), label: countryList[k].name} })}
                  onChange={countryId.onChange}
                  selected={countryId.value}
                />
              <InputSelect type="checkbox" checked={active.checked} label="Is Active" value={active.value} onChangeValue={active.onChange} />
              <InputSelect type="checkbox" checked={active.checked} label="Pay by Card" value={payByCard.value} onChangeValue={payByCard.onChange} />
              <InputSelect type="checkbox" checked={active.checked} label="Use low quality thumbnails" value={useLowQualityThumbnails.value} onChangeValue={useLowQualityThumbnails.onChange} />
              <InputSelect type="checkbox" checked={active.checked} label="Scrub personal data once order completes" value={scrubData.value} onChangeValue={scrubData.onChange} />
            </Box>
            <Box>
              <Maps address={mapsAddress} />
            </Box>
          </Grid>
        </Card>

        <Card padding="0">
          <Box padding={`${DESKTOP_SPACING.EL}px`}>
            <Accordion
              header={<HeadingIcon icon="contacts" title="Contact Details" />}
              onToggle={() => null}
              showArrow
              state="open"
            >
              <Grid columnGap={DESKTOP_SPACING.XL} gridTemplateColumns="repeat(2, 40%)" margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
                <InputText type="text" label="Contact Name" value={contactName.value} onChange={contactName.onChange} />
                <InputText type="text" label="E-mail Address" value={contactEmail.value} onChange={contactEmail.onChange} />
                <InputText type="text" label="Phone Number 1" value={contactPhone1.value} onChange={contactPhone1.onChange} />
                <InputText type="text" label="Phone Number 2" value={contactPhone2.value} onChange={contactPhone2.onChange} />
              </Grid>
            </Accordion>
          </Box>
        </Card>
      </PageWrapper>
      <Footer>
        <Button onClick={handleSave}>Save</Button>
      </Footer>
    </>
  );
};

export default memo(Customer);
