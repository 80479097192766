import { ApiLogin, GoogleLoginBody, LoginBody, SessionApi } from "./interface";
import { useCallback, useState } from "react";

import { ApiHooksProps } from "../interface";
import { User } from "@/types";
import { mapLoginResponse } from "./mappers";
import { request } from "../request";

const SESSION_NAME = 'jr-user';

const hook = ({ serverPath, setError, setLoading, setSuccess }: ApiHooksProps, setToken: React.Dispatch<React.SetStateAction<string>>): SessionApi => {
  const [user, setUser] = useState<User | null>(null);

  const rememberUser = useCallback((isLocalStorage: boolean, login: ApiLogin) => {
    const value = JSON.stringify({
      time: (new Date()).getTime(),
      val: login,
    });

    if (isLocalStorage) {
      localStorage.setItem(SESSION_NAME, value);
      sessionStorage.removeItem(SESSION_NAME);
    } else {
      sessionStorage.setItem(SESSION_NAME, value);
      localStorage.removeItem(SESSION_NAME);
    }
  }, []);

  const saveData = useCallback((data: ApiLogin) => {
    const mappedResponse = mapLoginResponse(data);
    setUser(mappedResponse.user);
    setToken(mappedResponse.session.token);
  }, []);

  const forgetUser = useCallback(() => {
    localStorage.removeItem(SESSION_NAME);
    sessionStorage.removeItem(SESSION_NAME);
  }, []);

  const getStoredUser = useCallback(() => {
    const sessionUser = sessionStorage.getItem(SESSION_NAME);

    if (sessionUser) {
      saveData(JSON.parse(sessionUser).val);
    }
    const localUser = localStorage.getItem(SESSION_NAME);

    if (localUser) {
      saveData(JSON.parse(localUser).val);
    }
  }, []);

  const isLoggedIn = useCallback(() => {
    return user != null;
  }, [user]);

  const login = useCallback(async (u: string, p: string, remember: boolean) => {
    setLoading(true);

    const path = `${serverPath}/accounts/login/`;
    const body: LoginBody = {
      Password: p,
      UserName: u,
    };

    try {
      const res = await request<LoginBody, ApiLogin>(path, { body });
      
      if (!res) {
        setError('Wrong user!');
        setLoading(false);
        
        throw new Error('Wrong user!');
      }
      rememberUser(remember, res);
      saveData(res)
      setLoading(false);
      setError(null);
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }

    return;
  }, []);

  const loginGoogle = useCallback(async (idToken: string) => {
    setLoading(true);

    const path = `${serverPath}/accounts/RegisterExternalToken/`;
    const body: GoogleLoginBody = {
      provider: 'Google',
      token: idToken
    };
    
    try {
      const res = await request<GoogleLoginBody, ApiLogin>(path, { body });
  
      rememberUser(false, res);
      saveData(res);
      setLoading(false);
      setError(null);
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    
    return;
  }, []);

  const getUserCountryId = useCallback(() => {
    return user?.countryId ?? 1;
  }, [user]);

  const getUserCurrency = useCallback(() => {
    return (user?.countryId || 0) <= 1 ? "£" : "$" 
  }, [user]);

  const isInRole = useCallback((role: string) => {
    return user?.roles?.includes(role) ?? false;
  }, [user]);

  const isInRoles = useCallback((roles: string[]) => {
    return roles.some((role) => user?.roles?.includes(role)) ?? false;
  }, [user]);

  const hasAdminRole = useCallback(() => {
    return isInRoles(['Admin', 'SuperUser', 'InOfficeStaff']);
  }, [user]);

  const hasCustomerRole = useCallback(() => {
    return isInRoles(['Customer']);
  }, [user]);

  const hasWorkerRole = useCallback(() => {
    return isInRoles(['Worker']);
  }, [user]);

  const logout = useCallback(async () => {
    forgetUser();
    setUser(null);
    setToken('');
  }, []);

  const resetPassword = useCallback(async (email: string) => {
    setLoading(true);

    const path = `${serverPath}/accounts/rest-password/`;
    const body = { AccountLogin: email };

    try {
      await request(path, { body });
      setLoading(false);
      setSuccess('Password reset, please check email');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }

    return;
  }, []);

  return {
    getStoredUser,
    getUserCountryId,
    getUserCurrency,
    hasAdminRole,
    hasCustomerRole,
    hasWorkerRole,
    isInRole,
    isInRoles,
    isLoggedIn,
    login,
    loginGoogle,
    logout,
    resetPassword,
    user,
  }
}

export default hook;