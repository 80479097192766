import {
  AccountDetailsCard,
  AdditionalInfoCard,
  ContactsCard,
  DateAndTimeCard,
  FreelanceOfferCard,
  KeysCard,
  NotesCard,
  OrderServiceCard,
  OrderStatusCard,
  PackageCard,
  PropertyAddressCard
} from "../Cards";
import { Button, ButtonsGroup, Heading, Link, PageHeader, Typo } from "#/atoms";
import { FC, memo } from "react";
import { FONT_SIZE, NATURAL } from "@/theme";

import { ORDER_STEP } from "../Order/Order.interface";
import { OrderBookingAndProgressProps } from "./BookingAndProgress.interface";
import { OrderExtraChargeCard } from "../Cards/OrderExtraCharge";

const Progress: FC<OrderBookingAndProgressProps> = ({
  activeStep,
  addressString,
  allowFreelance,
  appointmentDate,
  appointmentDuration,
  appointmentTime,
  bedrooms,
  coordinator,
  daysToPayInvoices,
  email,
  firstName,
  lastName,
  login,
  phoneNumber,
  contacts,
  contactVendorDirectly,
  description,
  doorNumber,
  flat,
  floorArea,
  keyNotes,
  keys,
  keysDate,
  keysTime,
  keysTimeOption,
  keysWith,
  keysWithDetail,
  notes,
  notifyViaSMS,
  offeredServices,
  orderExtraChargeDescription,
  orderExtraChargePriceExVat,
  orderId,
  orderOfferPrice,
  orderState,
  onAddCustomerContact,
  onAddNote,
  onAskToChangeWorker,
  onAskToEditContact,
  onAskToRemoveContact,
  onAskToAddNewContact,
  onChangePackage,
  onChangeRole,
  onDeleteFiles,
  onDownloadFiles,
  onTakeRole,
  onNotifyCustomer,
  onUploadFiles,
  parking,
  poNumber,
  preferredWorker,
  selectedOrderTemplate,
  selectedWorker,
  services,
  virtualTourPartial
}) => { 
  const accordionState = 'open';

  return (
    <>
      <PageHeader>
        <Heading scale="h1" fontColor={NATURAL.K}>{`Job ${orderId}:`} {description}</Heading>
        {activeStep === ORDER_STEP.PROGRESS && 
          <Link fontColor={NATURAL.G} fontSize={FONT_SIZE.SM} style={{order: 3, width: '100%'}}>{'CUSTOMER INFO'}</Link>
        }
        {activeStep !== ORDER_STEP.PROGRESS &&
         <Typo fontColor={NATURAL.G} fontSize={FONT_SIZE.SM}>Please provide us information about your order.</Typo>
        }
        <ButtonsGroup>
          <Button actionType='outline' onClick={onChangePackage}>Change Package</Button>
          {activeStep === ORDER_STEP.PROGRESS && <Button onClick={onAskToChangeWorker}>Assign Photographer for All Services</Button>}
          
        </ButtonsGroup>
        <Link style={{order: 3}}>
            <img src='https://photoplanbookings.com/images/invoice_active.png'></img>
        </Link>
      </PageHeader>
      
      {activeStep === ORDER_STEP.PROGRESS && (
        <AccountDetailsCard
          accordionState={accordionState}
          daysToPayInvoices={daysToPayInvoices}
          email={email}
          firstName={firstName}
          lastName={lastName}
          login={login}
          phoneNumber={phoneNumber}
        />
      )}
      <DateAndTimeCard
        accordionState={accordionState}
        appointmentDate={appointmentDate}
        appointmentTime={appointmentTime}
        appointmentDuration={appointmentDuration}
        contactVendorDirectly={contactVendorDirectly}
        notifyViaSMS={notifyViaSMS}
      />

      {activeStep === ORDER_STEP.PROGRESS && (
        <OrderStatusCard
          coordinator={coordinator}
          onChangeRole={onChangeRole}
          onTakeRole={onTakeRole}
          orderState={orderState} 
        />
      )}
      
      <PropertyAddressCard
        accordionState={accordionState}
        addressString={addressString}
        flat={flat}
        doorNumber={doorNumber}
      />

      <KeysCard
        accordionState={accordionState}
        keys={keys}
        keysDate={keysDate}
        keysTime={keysTime}
        keysTimeOption={keysTimeOption}
        keysWith={keysWith}
        keysWithDetail={keysWithDetail}
        keyNotes={keyNotes}
      />
      <AdditionalInfoCard
        accordionState={accordionState}
        poNumber={poNumber}
        bedrooms={bedrooms}
        floorArea={floorArea}
        parking={parking}
        virtualTourPartial={virtualTourPartial}
      />
      <ContactsCard
        accordionState={accordionState}
        contacts={contacts}
        onAddCustomerContact={onAddCustomerContact}
        onAskToEditContact={onAskToEditContact}
        onAskToRemoveContact={onAskToRemoveContact}
        onNewContact={onAskToAddNewContact}
      />
      <NotesCard
        accordionState={accordionState}
        notes={notes}
        onAddNote={onAddNote}
      />

      {activeStep === ORDER_STEP.PROGRESS && selectedOrderTemplate && (
        <PackageCard
          onNotifyCustomer={onNotifyCustomer}
          order={selectedOrderTemplate}
        />
      )}
      {activeStep === ORDER_STEP.PROGRESS && (
        <OrderServiceCard
          offeredServices={offeredServices}
          onAskToChangeWorker={onAskToChangeWorker}
          onDeleteFiles={onDeleteFiles}
          onDownloadFiles={onDownloadFiles}
          onUploadFiles={onUploadFiles}
          selectedWorker={selectedWorker}
          services={services}
        />
      )}

      {activeStep !== ORDER_STEP.PROGRESS && (
        <FreelanceOfferCard
          onChangeRole={onAskToChangeWorker}
          accordionState={accordionState}
          allowFreelance={allowFreelance}
          orderOfferPrice={orderOfferPrice}
          preferredWorker={preferredWorker}
        />
      )}

      <OrderExtraChargeCard
        accordionState={accordionState}
        orderExtraChargeDescription={orderExtraChargeDescription}
        orderExtraChargePriceExVat={orderExtraChargePriceExVat}
      />
    </>
  );
};

export default memo(Progress);
