import { Alert, Box, Button, ButtonsGroup, GlobalStyles, Heading, Icon, InputSelect, InputText, Link } from "#/atoms";
import { DESKTOP_SPACING, HPOSITION, NATURAL } from "@/theme";
import { FC, memo, useCallback, useState } from "react";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { StyledGoogleButton, StyledGoogleIcon, StyledGoogleText, StyledLogin, StyledLoginForm, StyledLogo, StyledSignup, StyledSignupForm, StyledSignupImage } from "./Login.styled";
import { useInput, useInputSelect } from "@/hooks";

import { ForgotPasswordModal } from "@/components/organisms/Modais/ForgotPassword";
import Google from "@/assets/icons/Google";
import { HelpModal } from "@/components/organisms/Modais/HelpModal";
import { LoginProps } from "./Login.interface";
import { PhotoplanLogo } from "#/atoms";
import bgImage from "@/assets/bg.jpeg";
import { useModal } from "@/hooks";

const googleClientId = '582449076537-k670t2ahjn2kt559aoiphl0pgbq0n3qv.apps.googleusercontent.com';

const Login: FC<LoginProps> = ({ onGoogleSuccess, onPasswordReset, onSignup, onSubmit }) => {
  const [error, setError] = useState<string | null>(null);
  const login = useInput();
  const password = useInput();
  const rememberMe = useInputSelect('remember')

  const { showModal: showModal, onCloseModal, onOpenModal } = useModal();
  const { showModal: showHelpModal, onCloseModal: onCloseHelpModal, onOpenModal: onOpenHelpModal } = useModal();

  const handleForgotPassword = useCallback(() => {
    onOpenModal();
  }, [showModal]);

  const handleLoginClick = useCallback(() => {
    setError(null);

    if (!login.value) {
      login.setError('Field email is required!');
      
      return;
    }

    if (!password.value) {
      login.setError('Field password is required!');
      
      return;
    }

    onSubmit(login.value, password.value, rememberMe.checked)
  }, [login, password, rememberMe]);

  const handleGoogleFailure = useCallback((response: { details: string }) => {
    setError(response.details);
  }, [login, password, rememberMe]);

  const handleGoogleSuccess = useCallback((response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    setError(null)
    onGoogleSuccess(response);
  }, [login, password, rememberMe]);

  const handlePasswordReset = useCallback((email: string) => {
    onPasswordReset(email);
    onCloseModal();
  }, []);
  
  const url = "https://share.getcloudapp.com/OAuoAj50";
  const embedUrl = `${url}?embed=true&amp;title=true`;

  const handleHelpClick = useCallback((e) => {
    e.stopPropagation();
    onOpenHelpModal();
  }, []);

  return (
    <>
      <GlobalStyles backgroundColor={NATURAL.A} />
      {error && <Alert type="error">{error}</Alert>}
      <StyledLogin>
        <StyledLogo>
          <PhotoplanLogo color={NATURAL.K} />
        </StyledLogo>
        <StyledLoginForm>
          <Heading scale="h1" fontColor={NATURAL.K}>Login to Your Account</Heading>
          <Box>
            <Icon type="question" fillColor={NATURAL.K} display="inline-block"/>
            <Link underline={true} onClick={handleHelpClick}>Click here for video guide</Link>
          </Box>
          <InputText type="email" label="Login" name="email" {...login} />
          <InputText type="password" label="Password" name="password" {...password} />
          <InputSelect
            checked={rememberMe.checked}
            type="checkbox"
            label="Remember me?"
            value={rememberMe.value}
            onChangeValue={rememberMe.onChange}
          />
          <Box style={{display: 'flex', justifyContent: 'space-between'}}>          
            <Button onClick={handleForgotPassword} style={{marginTop: '20px'}}>Forgot password</Button> 
            <ButtonsGroup align={HPOSITION.RIGHT} margin={`${DESKTOP_SPACING.XXL}px 0 0`}>
              <GoogleLogin
                clientId={googleClientId}
                render={renderProps => (
                  <StyledGoogleButton
                    disabled={renderProps.disabled}
                    onClick={renderProps.onClick}
                  >
                    <StyledGoogleIcon>
                      <Google />
                    </StyledGoogleIcon>
                    <StyledGoogleText>
                      Sign in with Google
                    </StyledGoogleText>
                    
                  </StyledGoogleButton>
                )}
                onSuccess={handleGoogleSuccess}
                onFailure={handleGoogleFailure}
              />
              <Button onClick={handleLoginClick}>Login</Button>
            </ButtonsGroup>
          </Box>
        </StyledLoginForm>
      </StyledLogin>
      <StyledSignup>
        <StyledSignupForm>
          <Heading scale="h1" fontColor={NATURAL.A}>New Here?</Heading>
          <ButtonsGroup align={HPOSITION.CENTER} fullWidth>
            <Button onClick={onSignup}>Sign Up</Button>
          </ButtonsGroup>
        </StyledSignupForm>
        <StyledSignupImage src={bgImage} />
      </StyledSignup>
      {showModal && (
        <ForgotPasswordModal
          onClose={onCloseModal}
          onPasswordReset={handlePasswordReset}
        />
      )}
      {showHelpModal && (
        <HelpModal
          onClose={onCloseHelpModal}
          url={url}
          embedUrl={embedUrl}
        />
      )}
    </>
  );
}

export default memo(Login);
