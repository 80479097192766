import { Navigate, useLocation } from "react-router-dom";
import { adminMenu, customerMenu } from "./constants";

import { Main } from "#/templates";
import { SupportModal } from "#/organisms";
import { useApi } from "@/api";
import { useCallback } from "react";
import { useModal } from "@/hooks";
import { Box } from "@/components/atoms";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { session, support } = useApi();
  const { showModal, onCloseModal, onOpenModal } = useModal();
  const location = useLocation();

  const createTicket = useCallback((subject: string, comment: string) => {
    support.createTicket(subject, comment);
  }, [support]);

  if (!session.isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (!session.user) {
    return null;
  }

  return (
    <Main
      user={{
        firstName: session.user.firstName,
        lastName: session.user.lastName,
      }}
      navigation={{
        menus: session.user.admin ? adminMenu : customerMenu,
        ...(!session.user.admin ? { support: {
          icon: 'help',
          id: 'support',
          label: 'Support',
          onClick: onOpenModal,
        }} : {})
      }}
      onLogout={session.logout}
    >
      <Box margin="70px 0 0px">
        {children}
      </Box>
      {showModal && (
        <SupportModal onClose={onCloseModal} onSend={createTicket} />
      )}
    </Main>
  );
}

export default RequireAuth;
