import styled, { css } from "styled-components";
import { PageWrapperProps } from "./PageWrapper.interface";

export const PageWrapper = styled.div<PageWrapperProps>(
  ({
    pb,
    margin,
    maxWidth
  }) => css`
  margin-top: 60px;
  padding-bottom: ${pb || '60px'};
  ${margin ? `margin: ${margin};` : 'margin: 0;'}
  max-width: ${maxWidth || ''};  
` 
);