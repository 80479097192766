import { MenuProps } from "#/molecules";

export const adminMenu: MenuProps[] = [
  {
    icon: 'dashboard',
    id: 'dashboard',
    label: 'Dashboard',
    path: '/'
  },
  {
    icon: 'orders',
    id: 'orders',
    label: 'Orders',
    notification: "0",
    state: 'closed',
    subMenu: [
      { id: 'orders', label: 'New Orders', path: '/orders-unmaintained' },
      { id: 'find-orders', label: 'Find Order', path: '/order-find' },
      { id: 'order-types', label: 'Services', path: '/order-types' },
      { id: 'order-create', label: 'Create Order', path: '/order-create' },
    ],
  },
  {
    icon: 'notifications',
    id: 'notifications',
    label: 'Notifications',
    notification: "0",
    state: 'closed',
    subMenu: [
      { id: 'outtakes-chosen', label: 'Outtakes Chosen', path: '/orders-outtakes' },
      { id: 'order-sms', label: 'Order SMSs', path: '/orders-notifications' },
      { id: 'expired-virtual-tours', label: 'Expired Virtual Tours', path: '/expired-virtual-tours' },
    ],
  },
  {
    icon: 'customers',
    id: 'customers',
    label: 'Customers',
    path: '/customer-find'
  },
  {
    icon: 'invoices',
    id: 'invoices',
    label: 'Invoices',
    notification: "0",
    state: 'closed',
    subMenu: [
      { id: 'generate-invoices', label: 'Generate Invoices', path: '/generate-invoices' },
      { id: 'my-invoices', label: 'My Invoices', path: '/invoices-find' },
    ],
  },
  {
    icon: 'settings',
    id: 'admin',
    label: 'Administration',
    state: 'closed',
    subMenu: [
      { id: 'system-users', label: 'System Users', path: '/users' },
      { id: 'workers', label: 'Photographers', path: '/workers' },
      { id: 'worker-invoices', label: 'Photographer Invoices', path: '/worker-invoices' },
      { id: 'worker-order-offers', label: 'Worker Order Offers', path: '/worker-order-offers' },
      { id: 'settings', label: 'Settings', path: '/settings' },
      { id: 'orders-report', label: 'Orders Report', path: '/orders-report' },
      { id: 'admin-calendar', label: 'Admin Calendar', path: '/admin-calendar' },
    ],
  },
];

export const customerMenu: MenuProps[] = [
  {
    icon: 'dashboard',
    id: 'dashboard',
    label: 'Dashboard',
    path: '/'
  },
  {
    icon: 'orders',
    id: 'orders',
    label: 'My Orders',
    path: '/customer-orders'
  },
  {
    icon: 'notifications',
    id: 'notifications',
    label: 'Notifications',
    state: 'closed',
    subMenu: [
      { id: 'expired-virtual-tours', label: 'Expired Virtual Tours', path: '/expired-virtual-tours' },
    ],
  },
  {
    icon: 'invoices',
    id: 'invoices',
    label: 'Invoices',
    state: 'closed',
    subMenu: [
      { id: 'my-invoices', label: 'My Invoices', path: '/invoices' },
    ],
  },
  {
    icon: 'language',
    id: 'language',
    label: 'Language',
    path: ''
  },
];

export const workerMenu: MenuProps[] = [
  {
    icon: 'dashboard',
    id: 'dashboard',
    label: 'Dashboard',
    path: '/'
  },
  {
    icon: 'orders',
    id: 'orders',
    label: 'My Tasks',
    path: '/worker-tasks'
  },
  {
    icon: 'calendar',
    id: 'orders',
    label: 'Calendar',
    path: '/worker-calendar'
  },
  {
    icon: 'language',
    id: 'language',
    label: 'Language',
    path: ''
  },
];