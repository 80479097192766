import { BORDER_RADIUS, DESKTOP_SPACING, NATURAL, ZINDEX } from "@/theme";
import styled, { css } from "styled-components";

import { StyledButton } from "@/components/atoms";
import { StyledTypo } from "@/components/atoms/Typography/Typo/Typo.styled";

export const StyledDropzone = styled.div`
  border-top: 1px solid ${NATURAL.C};
  margin-top: ${DESKTOP_SPACING.EL}px;
  padding-top: ${DESKTOP_SPACING.EL}px;
`;

export const StyledPhotoImg = styled.div<{ img: string }>`
  background: url("${({ img }) => img}") no-repeat;
  background-size: cover;
  border-radius: ${BORDER_RADIUS.LG};
  display: flex;
  height: 100%;
  margin-bottom: ${DESKTOP_SPACING.MD}px;
  overflow: hidden;
  width: 100%;
`;

export const StyledThumb = styled.div`
  height: 150px;
  position: relative;
  width: 187px;
`;

export const StyledPhotos = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${DESKTOP_SPACING.XXL}px;
`;

const buttonStyles = css`
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  border: 0;
  border-radius: ${BORDER_RADIUS.LG};
  color: ${NATURAL.G};
  display: flex;
  fill: ${NATURAL.G};
  justify-content: center;
  height: 30px;
  padding: ${DESKTOP_SPACING.SM}px;
  position: absolute;
  width: 30px;
`;

export const StyledEditButton = styled.button`
  ${buttonStyles}
  left: 6px;
  top: 6px;
`;

export const StyledAcceptButton = styled.button`
  ${buttonStyles}
  right: 6px;
  top: 6px;
`;

export const StyledCounter = styled.span`
  ${buttonStyles}
  bottom: 6px;
  left: 6px;
`;

export const StyledExpandButton = styled.button`
  ${buttonStyles}
  bottom: 6px;
  right: 6px;
`;

export const StyledOrderService = styled.div`
  align-items: center;
  border-bottom: 1px solid ${NATURAL.C};
  display: flex;
  padding-bottom: ${DESKTOP_SPACING.LG}px;
  margin-bottom: ${DESKTOP_SPACING.EL}px;
  width: 100%;

  ${StyledTypo} {
    flex: 1;
  }
`;

export const StyledImageSection = styled.div`
  margin-top: ${DESKTOP_SPACING.EL}px;
`;

export const StyledFullscreen = styled.div`
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${ZINDEX.MODAL_INDEX};

  ${StyledButton} {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: ${ZINDEX.MODAL_INDEX + 10};
  }

  :after {
    background: ${NATURAL.K};
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`;

export const StyledImage = styled.img`
  left: 10vw;
  max-height: 80vh;
  max-width: 80vw;
  position: absolute;
  top: 10vh;
  z-index: ${ZINDEX.MODAL_INDEX + 1};
`;
