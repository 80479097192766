import { FC, memo } from "react";

import { DashboardCustomerProps } from "./DashboardCustomer.interface"

const DashboardCustomer: FC<DashboardCustomerProps> = ({
    firstName
}) => {
    return (
        <h1>{firstName}</h1>
    )
}

export default memo(DashboardCustomer);